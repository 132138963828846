import { useTheme } from '@mui/material/styles';
import { Box, Divider, Grid, Link, Stack, Typography, VideoDialog } from '@talentmesh/core';
import React from 'react';
import { calendlyOnboardingUrl, onBoardVideoSource, resourceBaseUrl } from '../../../../../../AppSettings';
import { CreatedAssessmentsState } from '../../../../../../Models/CreatedAssessmentsState';
import UIStrings from '../../../../../../Utils/UIStrings';
import { useAssessmentsOverviewContext } from '../../../Contexts/AssessmentsOverviewContext';
import CreateRecruitmentButton from '../../CreateRecruitmentButton';
import LinkButton from './ContentSection/LinkButton';

function ContentSection(): JSX.Element | null {
    const theme = useTheme();

    const { createdAssessmentsState } = useAssessmentsOverviewContext();
    const hasAnyAssessments = createdAssessmentsState === CreatedAssessmentsState.HasAnyAssessments;

    if (hasAnyAssessments) {
        return null;
    }

    return (
        <Grid container spacing={2.5} mt={theme.spacing(4.25)} sx={{ placeContent: 'center' }}>
            <Grid item md={6}>
                <Typography variant="h4" align="center" mb={theme.spacing(2.25)}>
                    {UIStrings.WatchOur4MinOnboardingVideo}
                </Typography>
                <VideoDialog
                    alt="TalentMesh Recruitment Platform Onboarding Video"
                    src={onBoardVideoSource}
                    thumbnail={`${resourceBaseUrl}/images/introduction-video-thumbnail.jpg`}
                />
            </Grid>
            <Grid item md="auto">
                <Stack spacing={3.75} divider={<Divider />}>
                    <Stack spacing={3.75}>
                        <Typography variant="h4" align="center" mb={theme.spacing(2.25)}>
                            {UIStrings.CreateAndShareASkillsBasedRecruitmentInJust1Min}
                        </Typography>
                        <Box alignSelf="center">
                            <CreateRecruitmentButton label={UIStrings.CreateASkillsBasedRecruitment} hideIcon />
                        </Box>
                    </Stack>
                    <Stack spacing={3.75}>
                        <Typography variant="h4" align="center" mb={theme.spacing(2.25)}>
                            {UIStrings.NeedHelpCreatingYourFirstSkillsBasedRecruitment}
                        </Typography>
                        <Box alignSelf="center">
                            <Link as={LinkButton} target="_blank" href={calendlyOnboardingUrl}>
                                {UIStrings.BookOnboardingMeeting}
                            </Link>
                        </Box>
                    </Stack>
                </Stack>
            </Grid>
        </Grid>
    );
}

export default ContentSection;

import { AutocompleteOption } from '@talentmesh/core';
import { RTEInitValue } from '@talentmesh/rte';
import * as yup from 'yup';
import autoMapping from '../../../../../Utils/autoMapping';
import { DefaultJobAd, JobAd } from '../../Models/JobAd';
import YupValidationErrors from '../../../../../Utils/YupValidationErrors';
import AutocompleteOptionType from '../../../../../Models/AutocompleteOptionType';
import UIStrings from '../../../../../Utils/UIStrings';
import { AssessmentStatuses } from '../../../../../Models/AssessmentData';

export const JobDescriptionCharactersLimit = 3000;

export enum JobAdFieldTypes {
    Title = 'title',
    HardSkills = 'hardSkills',
    HardSkillsSwitch = 'hardSkillsSwitch',
    SoftSkills = 'softSkills',
    SoftSkillsSwitch = 'softSkillsSwitch',
    Description = 'description',
    DescriptionLen = 'descriptionLen',
    CompanyDescription = 'companyDescription',
}

export interface JobAdFormValues {
    title: string;
    hardSkills: AutocompleteOption[];
    hardSkillsSwitch: boolean;
    softSkills: AutocompleteOption[];
    softSkillsSwitch: boolean;
    description: string;
    descriptionLen: number;
    companyDescription: string;
    status: AssessmentStatuses;
}

export const DefaultJobAdFormValues: JobAdFormValues = {
    title: '',
    hardSkills: [],
    hardSkillsSwitch: false,
    softSkills: [],
    softSkillsSwitch: false,
    description: RTEInitValue, // we use this initial value for correct RTE behavior is we add only list in description
    descriptionLen: 0,
    companyDescription: RTEInitValue,
    status: AssessmentStatuses.NonPublic,
};

export function jobAdModel2FormValues(model: JobAd): JobAdFormValues {
    return autoMapping(model, DefaultJobAdFormValues);
}

export function jobAdFormValues2Model(values: JobAdFormValues): JobAd {
    return autoMapping(values, DefaultJobAd);
}

export function getAutocompleteSkillsType(type: AutocompleteOptionType): string {
    switch (type) {
        case AutocompleteOptionType.HardSkill:
            return JobAdFieldTypes.HardSkills;
        case AutocompleteOptionType.SoftSkill:
            return JobAdFieldTypes.SoftSkills;
        default:
            return '';
    }
}

export function fillInInitialSkills(
    initialFormValues: JobAdFormValues,
    existingFormValues: JobAdFormValues,
): JobAdFormValues {
    // we always take skills from existing form values
    // because user can edit those during job ad generation / improvement
    return {
        ...initialFormValues,
        hardSkills: existingFormValues.hardSkills,
        softSkills: existingFormValues.softSkills,
        hardSkillsSwitch: existingFormValues.hardSkillsSwitch,
        softSkillsSwitch: existingFormValues.softSkillsSwitch,
    };
}

export function getSkillsSwitchName(type: AutocompleteOptionType): string {
    switch (type) {
        case AutocompleteOptionType.HardSkill:
            return JobAdFieldTypes.HardSkillsSwitch;
        case AutocompleteOptionType.SoftSkill:
            return JobAdFieldTypes.SoftSkillsSwitch;
        default:
            return '';
    }
}

export const jobAdValidationScheme = yup.object().shape({
    title: yup
        .string()
        .required(YupValidationErrors.Required)
        .max(256, YupValidationErrors.JobAdTitleMaxSymbolsValidationError),
    hardSkillsSwitch: yup.boolean(),
    hardSkills: yup
        .array()
        .ensure()
        .when(getSkillsSwitchName(AutocompleteOptionType.HardSkill), {
            is: true,
            then: yup
                .array()
                .test(
                    'custom-validation',
                    YupValidationErrors.PleaseAddAtLeastOneSkillRequiredError('hard'),
                    (value) => {
                        return !!value && value.length > 0;
                    },
                ),
        }),
    softSkillsSwitch: yup.boolean(),
    softSkills: yup
        .array()
        .ensure()
        .when(getSkillsSwitchName(AutocompleteOptionType.SoftSkill), {
            is: true,
            then: yup
                .array()
                .test(
                    'custom-validation',
                    YupValidationErrors.PleaseAddAtLeastOneSkillRequiredError('soft'),
                    (value) => {
                        return !!value && value.length > 0;
                    },
                ),
        }),
    description: yup.string(),
    descriptionLen: yup
        .number()
        .min(1, YupValidationErrors.JobDescriptionRequiredError)
        .max(JobDescriptionCharactersLimit, YupValidationErrors.JobDescriptionLimitError),
    companyDescription: yup
        .string()
        .test('Required', UIStrings.Required, (value) => {
            const plainText = extractTextWithNewLinesFromHtml(value || '');
            return plainText.length !== 0;
        })
        .test('TooLong', UIStrings.CompanyDescriptionInputLengthValidation, (value) => {
            const plainText = extractTextWithNewLinesFromHtml(value || '');
            return plainText.length < 4000;
        }),
});

export function extractTextWithNewLinesFromHtml(html: string): string {
    const document = new DOMParser().parseFromString(html, 'text/html').documentElement;

    const elementsWithLineBreaks = ['p', 'h4', 'li'];
    elementsWithLineBreaks.forEach((elementName) => {
        const elements = document.getElementsByTagName(elementName);
        for (let i = 0; i < elements.length; i++) {
            const element = elements[i];
            element.textContent = `${element.textContent}\n`;
        }
    });
    return document.textContent?.trim() || '';
}

export function jobAdDeepEqual(ja1: JobAd, ja2: JobAd) {
    const hardSkills1 = ja1.hardSkills;
    const hardSkills2 = ja2.hardSkills;
    const softSkills1 = ja1.softSkills;
    const softSkills2 = ja2.softSkills;

    const sortedSkillsDeepEqual = (skills1: AutocompleteOption[] = [], skills2: AutocompleteOption[] = []) => {
        if (skills1.length !== skills2.length) {
            return false;
        }

        return skills1.every(({ label }, i) => label === skills2[i].label);
    };

    return (
        ja1.companyDescription === ja2.companyDescription &&
        extractTextWithNewLinesFromHtml(ja1.description) === extractTextWithNewLinesFromHtml(ja2.description) && // here is extracted because generated job ad content is not a 1:1 perfect replication of what lexical rte value suppose to be, so the best solution here is to just compare texts
        ja1.descriptionLen === ja2.descriptionLen &&
        sortedSkillsDeepEqual(hardSkills1?.sort(), hardSkills2?.sort()) &&
        ja1.hardSkillsSwitch === ja2.hardSkillsSwitch &&
        sortedSkillsDeepEqual(softSkills1?.sort(), softSkills2?.sort()) &&
        ja1.softSkillsSwitch === ja2.softSkillsSwitch &&
        ja1.status === ja2.status &&
        ja1.title === ja2.title
    );
}

import React from 'react';
import { Button } from '@talentmesh/core';
import { AssignmentOutlinedIcon } from '@talentmesh/icons';
import RoutePath from '../../../../Routing/RoutePath';
import UIStrings from '../../../../Utils/UIStrings';
import useTalentMeshHistory from '../../../../Routing/useTalentMeshHistory';

interface CreateRecruitmentButtonProps {
    label?: string;
    hideIcon?: boolean;
    hidden?: boolean;
}

const CreateRecruitmentButton = ({ label, hideIcon, hidden }: CreateRecruitmentButtonProps) => {
    const history = useTalentMeshHistory();

    const handleAssessmentCreateClick = async () => {
        history.push(RoutePath.CreateAssessment);
    };

    return (
        <Button
            startIcon={hideIcon ? null : <AssignmentOutlinedIcon />}
            color="primary"
            variant="contained"
            size="large"
            onClick={handleAssessmentCreateClick}
            sx={{ visibility: hidden ? 'hidden' : 'unset' }}
        >
            {label || UIStrings.CreateRecruitment}
        </Button>
    );
};

export default CreateRecruitmentButton;

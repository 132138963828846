import React from 'react';
import { useTheme } from '@mui/material/styles';
import { JobAdPage, JobAdPageProps } from '@talentmesh/job-ad';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@talentmesh/core';
import { CloseIcon } from '@talentmesh/icons';
import PreviewDisclaimer from './PreviewDisclaimer';

interface JobAdPreviewDialogProps extends JobAdPageProps {
    open: boolean;
    handleClose: () => void;
}

function JobAdPagePreviewDialog({ open, handleClose, jobAd, settings }: JobAdPreviewDialogProps): JSX.Element {
    const theme = useTheme();
    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            sx={{
                padding: 0,
            }}
        >
            <DialogTitle
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingBottom: 0,
                }}
            >
                <PreviewDisclaimer />
                <IconButton edge="end" color="inherit" onClick={handleClose} data-cy="ClosePreviewDialogButton">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent
                sx={{
                    paddingLeft: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    marginTop: theme.spacing(5),
                }}
            >
                <JobAdPage
                    jobAd={jobAd}
                    settings={{
                        ...settings,
                        preview: true,
                    }}
                />
            </DialogContent>
        </Dialog>
    );
}

export default JobAdPagePreviewDialog;

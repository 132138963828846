import React from 'react';
import { useTheme } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { Stack, Label, FormHelperText } from '@talentmesh/core';
import { $generateHtmlFromNodes, RichTextEditor } from '@talentmesh/rte';
import UIStrings from '../../../../../../Utils/UIStrings';
import { useModifyRecruitmentContext } from '../../../Contexts/ModifyRecruitmentContext';
import useModifyRecruitmentContextHook from '../../../Contexts/useModifyRecruitmentContextHook';
import ProcessingState from '../../../../../../Models/ProcessingState';
import { JobAdFieldTypes, extractTextWithNewLinesFromHtml } from '../JobAdStepUtils';
import RTELoadingOverlay from './RTELoadingOverlay';
import CompanyDescriptionWings from './CompanyDescriptionWings.svg';
import { useCompanyContext } from '../../../../../../Context/CompanyContext';
import { isDefaultLanguage } from '../../../../../../Utils/JobAdLanguage/JobAdLanguages';

interface JobAdCompanyDescriptionProps {
    initialHtml: string;
}

function JobAdCompanyDescription({ initialHtml }: JobAdCompanyDescriptionProps): JSX.Element {
    const action = useModifyRecruitmentContext();
    const { processingState, jobDetails } = useModifyRecruitmentContextHook(action);
    const loading = processingState === ProcessingState.Processing;
    const theme = useTheme();
    const [, meta, helpers] = useField(JobAdFieldTypes.CompanyDescription);
    const { isSubmitting } = useFormikContext();
    const {
        currentCompanyProfile: { companyDescription },
    } = useCompanyContext();

    const isCompanyDescriptionEmpty = extractTextWithNewLinesFromHtml(companyDescription) === '';
    const showLoadingOverlay = isCompanyDescriptionEmpty || !isDefaultLanguage(jobDetails.jobDescriptionLanguage);

    return (
        <Stack>
            <Label>{UIStrings.CompanyDescription}</Label>
            <Stack
                sx={{
                    borderRadius: theme.spacing(1.25),
                    border: `1px solid ${theme.palette.border.main}`,
                    borderWidth: '1px',
                    backgroundColor: theme.palette.grey[100],
                    borderColor: meta.touched && meta.error ? theme.palette.error.main : theme.palette.border.main,
                }}
                display="flex"
                flexDirection="column"
                paddingLeft={theme.spacing(2.5)}
                paddingTop={theme.spacing(2.5)}
                paddingRight={theme.spacing(2.5)}
                paddingBottom={theme.spacing(2.5)}
                height={theme.spacing(43)}
                data-cy="CompanyDescriptionRTE"
                spacing={theme.spacing(1)}
            >
                <RichTextEditor
                    editable={!isSubmitting}
                    loading={loading && showLoadingOverlay}
                    loadingOverlay={
                        <RTELoadingOverlay dataCy="CompanyDescriptionLoadingOverlay" svg={CompanyDescriptionWings} />
                    }
                    data-cy={JobAdFieldTypes.CompanyDescription}
                    initialHtml={initialHtml}
                    features={['headingStyle', 'bold', 'italic', 'ol', 'ul']}
                    onChange={(editorState, editor) => {
                        editorState.read(() => {
                            helpers.setValue($generateHtmlFromNodes(editor));
                        });
                    }}
                    contentEditableProps={{
                        sx: {
                            overflowY: 'auto',
                            flex: 1,
                            color: isSubmitting ? theme.palette.text.disabled : undefined,
                        },
                    }}
                    toolbarProps={{ sx: { marginBottom: theme.spacing(1.25) } }}
                />
                {meta.touched && meta.error && <FormHelperText error>{meta.error}</FormHelperText>}
            </Stack>
        </Stack>
    );
}

export default JobAdCompanyDescription;

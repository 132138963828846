import React from 'react';
import { Box, Paper, SpanTypography, Stack } from '@talentmesh/core';
import { ArrowUpwardIcon, ArrowDownwardIcon } from '@talentmesh/icons';
import { useTheme } from '@mui/material/styles';
import UIStrings from '../../../../../../Utils/UIStrings';

interface RelevantQuestionItemProps {
    isHigh: boolean;
    characteristicName: string;
    aspectDescription: string;
}
function RelevantQuestionItem({
    isHigh,
    characteristicName,
    aspectDescription,
}: RelevantQuestionItemProps): JSX.Element {
    const theme = useTheme();

    const borderRadius = theme.shape.borderRadius as number;

    const getColorByHigh = (value: boolean) => {
        return value ? theme.palette.charts.chart9 : theme.palette.charts.chart10;
    };

    return (
        <Paper
            elevation={0}
            sx={{
                border: `1px solid ${theme.palette.border.main}`,
                borderRadius: `${borderRadius}px`,
            }}
        >
            <Stack direction="row" justifyContent="space-between" alignItems="stretch" spacing={2}>
                <Box
                    sx={{
                        borderTopLeftRadius: `${borderRadius - 1}px`,
                        borderBottomLeftRadius: `${borderRadius - 1}px`,
                        borderLeft: `${borderRadius}px solid ${getColorByHigh(isHigh)}`,
                    }}
                />

                <Box sx={{ p: 3, display: 'flex', alignItems: 'center' }}>
                    {isHigh ? (
                        <ArrowUpwardIcon sx={{ color: getColorByHigh(true) }} />
                    ) : (
                        <ArrowDownwardIcon sx={{ color: getColorByHigh(false) }} />
                    )}
                </Box>

                <Box
                    sx={{
                        p: 2,
                        paddingRight: 3,
                    }}
                >
                    <Box>
                        {isHigh && <SpanTypography variant="body1">{UIStrings.YouVeScoredWellFor}</SpanTypography>}
                        {!isHigh && <SpanTypography variant="body1">{UIStrings.YouVeNotScoredWellFor}</SpanTypography>}
                        <SpanTypography
                            variant="body1"
                            sx={{ fontWeight: 'bold' }}
                        >{` ${characteristicName}, `}</SpanTypography>
                        <SpanTypography variant="body1">
                            {UIStrings.TestResultsShowThatYouIdentifyYourselfAsSomeoneWho}
                        </SpanTypography>
                        <SpanTypography
                            variant="body1"
                            sx={{ fontWeight: 'bold' }}
                        >{` ${aspectDescription}.`}</SpanTypography>
                    </Box>
                    <Box sx={{ paddingTop: 1 }}>
                        <SpanTypography>{UIStrings.DoesThisDescriptionAccuratelyRepresentYou}</SpanTypography>
                    </Box>
                </Box>
            </Stack>
        </Paper>
    );
}

export default RelevantQuestionItem;

import { useTheme } from '@mui/material';
import { Box, Button, Stack, TMCircularProgress, Typography } from '@talentmesh/core';
import { EditIcon } from '@talentmesh/icons';
import React from 'react';
import UIStrings from '../../../../../../../Utils/UIStrings';
import { ExperienceCriterionFinalScore } from '../../../../../../../Models/ExperienceCriterionFinalScore';
import AdjustedByRecruiterChip from './AdjustedByRecruiterChip';

interface CriteriaRowProps {
    criterionFinalScore: ExperienceCriterionFinalScore;
    index: number;
    onEdit: (criterionScoreToEdit: ExperienceCriterionFinalScore) => void;
}

const CriteriaRow = ({ criterionFinalScore, index, onEdit }: CriteriaRowProps) => {
    const theme = useTheme();
    const { criterionScore, criterion, justification } = criterionFinalScore;

    return (
        <Stack
            spacing={2.5}
            direction="row"
            sx={{
                py: theme.spacing(5),
                px: theme.spacing(2.5),
                color: theme.palette.text.menuTitle,
                placeItems: 'start',
            }}
        >
            <TMCircularProgress level={criterionScore?.scoreLevel} progress={criterionScore?.score} />
            <Stack spacing={1.25} width="100%">
                <Stack direction="row">
                    <Box width="100%">
                        <Typography variant="h4">{`${UIStrings.Criteria(index + 1)}:`}</Typography>
                        <Typography variant="h5">{criterion}</Typography>
                    </Box>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        startIcon={<EditIcon />}
                        onClick={() => onEdit(criterionFinalScore)}
                    >
                        {UIStrings.Edit}
                    </Button>
                </Stack>
                <Box>
                    <Typography variant="body1" fontWeight={theme.typography.fontWeightBold}>
                        {UIStrings.Justification}:
                    </Typography>
                    <Typography variant="body1">{justification}</Typography>
                </Box>
                {criterionFinalScore.isUserDefinedScore && (
                    <Stack justifyContent="flex-end" direction="row">
                        <AdjustedByRecruiterChip />
                    </Stack>
                )}
            </Stack>
        </Stack>
    );
};

export default CriteriaRow;

import { Checkbox } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { IconButton, Stack, TableRow, Typography, TypographyIcon, TMTableCell } from '@talentmesh/core';
import {
    CreateOutlinedIcon,
    MailOutlineIcon,
    PersonOutlineOutlinedIcon,
    PersonPinOutlinedIcon,
    RemoveCircleOutlineIcon,
} from '@talentmesh/icons';
import InternalBenchmarkBadge from '../../../../../Components/InternalBadges';
import { CandidateAction } from '../../../../../Models/Candidate';
import { CandidateRowEntry } from '../../../../../Models/CandidateRowEntry';
import { useInviteCandidateContext } from '../../Contexts/InviteCandidateContext';

export interface CandidateRowProps {
    candidate: CandidateRowEntry;
    setEditableCandidate: CandidateAction;
}

export const CandidateViewRow = ({ candidate, setEditableCandidate }: CandidateRowProps): JSX.Element => {
    const theme = useTheme();
    const { selectedCandidateIds, selectCandidate, deleteCandidateAsync } = useInviteCandidateContext();
    const [checked, setChecked] = useState<boolean>(false);
    const cellBorder = !candidate.isReadOnly ? theme.palette.border.main : theme.palette.action.disabledBackground;

    useEffect(() => {
        setChecked(selectedCandidateIds.findIndex((x) => x === candidate.id) >= 0);
    }, [selectedCandidateIds]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.checked;
        setChecked(value);

        selectCandidate(candidate, value);
    };

    return (
        <TableRow
            sx={{
                background: !candidate.isReadOnly
                    ? theme.palette.common.white
                    : theme.palette.action.disabledBackgroundLight,
                height: theme.spacing(7.25),
                '& td, td:first-of-type, td:last-of-type': { borderColor: cellBorder },
            }}
        >
            <TMTableCell width={theme.spacing(6)} isInternal={candidate.isInternal}>
                <Stack direction="row" spacing={1}>
                    <Checkbox
                        checked={checked}
                        onChange={handleChange}
                        style={{ visibility: !candidate.isReadOnly ? 'unset' : 'hidden' }}
                        data-cy="checkbox"
                    />
                    <TypographyIcon
                        icon={candidate.isInternal ? <PersonPinOutlinedIcon /> : <PersonOutlineOutlinedIcon />}
                        variant="h5"
                        color={!candidate.isReadOnly ? theme.palette.secondary.main : theme.palette.text.disabled}
                    />
                </Stack>
            </TMTableCell>
            <TMTableCell width={theme.spacing(50)} isInternal={candidate.isInternal}>
                <Typography
                    variant="h5"
                    color={!candidate.isReadOnly ? theme.palette.secondary.main : theme.palette.text.disabled}
                >
                    {candidate.firstName}
                </Typography>
            </TMTableCell>
            <TMTableCell width={theme.spacing(50)} isInternal={candidate.isInternal}>
                <Typography
                    variant="h5"
                    color={!candidate.isReadOnly ? theme.palette.secondary.main : theme.palette.text.disabled}
                >
                    {candidate.lastName}
                </Typography>
            </TMTableCell>
            <TMTableCell width={theme.spacing(50)} isInternal={candidate.isInternal}>
                <TypographyIcon
                    icon={<MailOutlineIcon />}
                    variant="h5"
                    sx={{ verticalAlign: 'bottom' }}
                    color={!candidate.isReadOnly ? theme.palette.secondary.main : theme.palette.text.disabled}
                >
                    {candidate.email}
                </TypographyIcon>
            </TMTableCell>
            <TMTableCell width={theme.spacing(12)} isInternal={candidate.isInternal}>
                {candidate.isInternal && <InternalBenchmarkBadge />}
            </TMTableCell>
            <TMTableCell width={theme.spacing(12)} isInternal={candidate.isInternal}>
                <Stack direction="row">
                    <IconButton
                        color="secondary"
                        onClick={() => {
                            setEditableCandidate(candidate);
                        }}
                        size="large"
                        style={{ visibility: !candidate.isReadOnly ? 'unset' : 'hidden' }}
                        data-cy="editButton"
                    >
                        <CreateOutlinedIcon />
                    </IconButton>
                    <IconButton
                        sx={{ color: theme.palette.error.main }}
                        onClick={async () => {
                            await deleteCandidateAsync(candidate);
                        }}
                        size="large"
                        style={{ visibility: !candidate.isReadOnly ? 'unset' : 'hidden' }}
                        data-cy="deleteButton"
                    >
                        <RemoveCircleOutlineIcon />
                    </IconButton>
                </Stack>
            </TMTableCell>
        </TableRow>
    );
};

import React from 'react';
import { ChevronRightIcon } from '@talentmesh/icons';
import ProcessingState from '../../../../../../../../Models/ProcessingState';
import UIStrings from '../../../../../../../../Utils/UIStrings';
import NextStepButton from '../../../../../Components/NextStepButton';
import { useModifyRecruitmentContext } from '../../../../../Contexts/ModifyRecruitmentContext';
import useModifyRecruitmentContextHook from '../../../../../Contexts/useModifyRecruitmentContextHook';

function NextButtonComponent(): JSX.Element {
    const action = useModifyRecruitmentContext();
    const { processingState } = useModifyRecruitmentContextHook(action);

    return (
        <NextStepButton loading={processingState === ProcessingState.Processing} endIcon={<ChevronRightIcon />}>
            {UIStrings.Next}
        </NextStepButton>
    );
}

export default NextButtonComponent;

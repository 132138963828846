import { useTheme } from '@mui/material';
import { Page } from '@talentmesh/core';
import React from 'react';
import TopBar from '../../../Components/TopBars/TopBar';
import { CreatedAssessmentsState } from '../../../Models/CreatedAssessmentsState';
import AssessmentsOverviewHeader from './Components/AssessmentsOverviewHeader';
import AssessmentsOverviewTabs from './Components/AssessmentsOverviewTabs';
import { useAssessmentsOverviewContext } from './Contexts/AssessmentsOverviewContext';

const AssessmentsOverviewPageContent = () => {
    const theme = useTheme();
    const { createdAssessmentsState } = useAssessmentsOverviewContext();
    const isFullPage = createdAssessmentsState === CreatedAssessmentsState.NoAssessments;

    return (
        <Page
            topBar={<TopBar />}
            header={!isFullPage && <AssessmentsOverviewHeader />}
            sx={{
                backgroundColor: isFullPage ? theme.palette.common.white : theme.palette.background.aliciaBlue,
            }}
            containerProps={{
                disableGutters: true,
            }}
            containerSx={{
                // this could be paddingY if only there's no hidden box at the end of the list
                paddingTop: theme.spacing(3.75),
                height: '100%',
            }}
        >
            <AssessmentsOverviewTabs />
        </Page>
    );
};

export default AssessmentsOverviewPageContent;

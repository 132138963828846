import { MenuItemWithValueProps } from '@talentmesh/core';
import { SyntheticEvent } from 'react';
import { useField } from 'formik';
import UIStrings from '../../../../../../Utils/UIStrings';
import { useModifyRecruitmentContext } from '../../../Contexts/ModifyRecruitmentContext';
import useModifyRecruitmentContextHook from '../../../Contexts/useModifyRecruitmentContextHook';
import { WritingTone } from '../../../../../../Models/WritingTone';
import { JobAdFieldTypes } from '../../JobAdStep/JobAdStepUtils';

function useImproveWithAIMenuItems(): MenuItemWithValueProps[] {
    const action = useModifyRecruitmentContext();
    const { improveJobAdAsync } = useModifyRecruitmentContextHook(action);
    const [title, titleMeta, titleHelpers] = useField(JobAdFieldTypes.Title);
    const [description, descriptionHepers, descriptionError] = useField(JobAdFieldTypes.Description);
    const [companyDescription, companyDescriptionHepers, companyDescriptionError] = useField(
        JobAdFieldTypes.CompanyDescription,
    );

    const onClickInner = async (e: SyntheticEvent, tone: WritingTone) => {
        e.stopPropagation();
        e.preventDefault();
        await improveJobAdAsync(tone, title.value, description.value, companyDescription.value);
    };

    const options: MenuItemWithValueProps[] = [
        {
            menuItemLabel: UIStrings.RewriteInMoreFriendlyTone,
            dense: true,
            onClick: async (e: SyntheticEvent) => await onClickInner(e, 'Friendly'),
        },
        {
            menuItemLabel: UIStrings.RewriteInMoreFormalTone,
            dense: true,
            onClick: (e: SyntheticEvent) => onClickInner(e, 'Formal'),
        },
    ];

    return options;
}

export default useImproveWithAIMenuItems;

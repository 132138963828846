import { useEffect, useRef, useState } from 'react';
import { JobAd } from '../../Models/JobAd';
import { jobAdDeepEqual, jobAdValidationScheme } from '../../Steps/JobAdStep/JobAdStepUtils';

/**
 * determine whether experience criteria should be generated when `jobAd` updates
 *
 * initially, this should be 0 meaning it shouldn't generate anything until jobad is valid
 * the useEffect later in the code where the return variable will be used should only trigger if the counter changes (it goes up)
 * @param jobAd should point and listen to a `jobAd` variable that is being updated
 * @returns a counter to trigger experience criteria to be generated
 */
const useShouldGenerateExperienceCriteria = (jobAd: JobAd) => {
    const [shouldGenerate, setShouldGenerate] = useState(0);
    const previousJobAdValues = useRef<JobAd>(jobAd);

    // useMemo here is good, but does not trigger useEffect in the context where this is used for some reason
    useEffect(() => {
        let result;

        try {
            jobAdValidationScheme.validateSync(jobAd);
            result = true;
        } catch {
            result = false;
        }

        if (result) {
            result = !jobAdDeepEqual(jobAd, previousJobAdValues.current);
        }

        previousJobAdValues.current = jobAd;

        if (result) {
            setShouldGenerate((p) => p + 1);
        }
    }, [jobAd]);

    return shouldGenerate;
};

export default useShouldGenerateExperienceCriteria;

import { useTheme } from '@mui/material';
import React from 'react';
import { Button } from '@talentmesh/core';
import { ChevronLeftIcon } from '@talentmesh/icons';
import UIStrings from '../../../../Utils/UIStrings';
import { useInvitationPageContext } from '../Contexts/InvitationPageContext';
import useTalentMeshHistory from '../../../../Routing/useTalentMeshHistory';

function BackButton(): JSX.Element {
    const theme = useTheme();
    const history = useTalentMeshHistory();
    const { returnURL: from } = useInvitationPageContext();

    const handleOnClick = () => {
        history.push(from);
    };

    return (
        <Button
            variant="text"
            size="small"
            startIcon={<ChevronLeftIcon />}
            sx={{
                color: theme.palette.secondary.main,
                margin: 0,
                padding: 0,
                display: 'flex',
                justifyContent: 'flex-start',
                ':active': {
                    backgroundColor: 'transparent',
                },
            }}
            onClick={handleOnClick}
        >
            {UIStrings.Back}
        </Button>
    );
}

export default BackButton;

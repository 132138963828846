import React, { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { RTEInitValue } from '@talentmesh/rte';
import {
    useAssessmentClient,
    useAutocompleteOptionsClient,
    useCareerClient,
    useCriteriaClient,
    useJobAdClient,
    useJobTestInfoClient,
    useQuestionsConfigurationClient,
    useSettingsClient,
} from '../../../../../Hooks/ClientHooks';
import { AssessmentStatus, TestTypes } from '../../../../../Models/Configuration';
import { Currency } from '../../../../../Models/Currency';
import ProcessingState from '../../../../../Models/ProcessingState';
import { ApplicantInformation } from '../../Models/ApplicantInformation';
import { DefaultJobAd, JobAd } from '../../Models/JobAd';
import { JobDetails } from '../../Models/JobDetails';
import { mapTestInfoDto2Model } from '../../Models/TestInfo';
import {
    CreateRecruitmentContextState,
    DefaultCreateRecruitmentContextState,
    mapCreateRecruitmentContextState2Dto,
} from './CreateRecruitmentContextState';
import GenerateJobAdDTO from '../../../../../DTO/JobAds/GenerateJobAdDTO';
import { getJobFunctionName } from '../../../../../Utils/JobFunctionSelectionUtils';
import { useAssessmentSettingsContext } from '../../../../../Context/AssessmentSettingsContext';
import JobAdMapper from '../../Steps/JobAdStep/Builder/JobAdMapper';
import { WritingTone } from '../../../../../Models/WritingTone';
import { extractTextWithNewLinesFromHtml } from '../../Steps/JobAdStep/JobAdStepUtils';
import ImproveJobAdDTO from '../../../../../DTO/JobAds/ImproveJobAdDTO';
import AIJobAdDTO from '../../../../../DTO/JobAds/AIJobAdDTO';
import { DemoQuestions, mapDemoQuestionsDto2Model } from '../../Models/Questions';
import useCompanyId from '../../../../../Hooks/UseCompanyId';
import { useCompanyContext } from '../../../../../Context/CompanyContext';
import { jobDetailsDeepEqual } from '../../Steps/JobDetailsStep/JobDetailsStepUtils';
import { isDefaultLanguage } from '../../../../../Utils/JobAdLanguage/JobAdLanguages';
import GenerateExperienceCriteriaDTO, {
    createGenerateExperienceCriteriaDTO,
} from '../../../../../DTO/ExperienceCriteria/GenerateExperienceCriteriaDTO';
import useShouldGenerateExperienceCriteria from '../Utils/useShouldGenerateExperienceCriteria';

interface ICreateRecruitmentContext extends CreateRecruitmentContextState {
    currencies: Currency[];
    locales: string[];
    gettingDataProcessingState: ProcessingState;

    setContextState: (value: CreateRecruitmentContextState) => void;
    setJobAd: (value: JobAd) => void;
    setIncludedTests: (value: TestTypes[]) => void;
    setRecruitmentCreationOption: (value: AssessmentStatus) => void;
    setApplicantInformation: (value: ApplicantInformation[]) => void;
    setProcessingState: (value: ProcessingState) => void;
    setTestLanguage: (value: string) => void;
    setExperienceCriteriaForm: (valueSet: {
        experienceCriteria: CreateRecruitmentContextState['experienceCriteria'];
        includesExperienceCriteria: CreateRecruitmentContextState['includesExperienceCriteria'];
    }) => void;
    resetExperienceCriteriaProcessingState: () => void;

    updateJobDetails: (newJobDetails: JobDetails) => Promise<void>;
    updateRecruitmentAsync: (values: JobAd) => Promise<void>;
    getCareerPageStatus: () => Promise<boolean>;
    createRecruitmentAsync: (assessmentStatus: AssessmentStatus, companyId: string) => Promise<string>;
    generateJobAdAsync: () => Promise<void>;
    improveJobAdAsync: (tone: WritingTone, title: string, jobDescription: string, companyDesc: string) => Promise<void>;
    getDemoQuestions: (testLang: string) => Promise<DemoQuestions>;

    closeDialog: () => void;
}

const CreateRecruitmentContext = createContext<ICreateRecruitmentContext | undefined>(undefined);

const CreateRecruitmentContextProvider = CreateRecruitmentContext.Provider;

interface CreateRecruitmentContextProviderProps {
    children: ReactNode;
}

export const CreateRecruitmentProvider = ({ children }: CreateRecruitmentContextProviderProps): JSX.Element => {
    const { updateCompanyProfile, currentCompanyProfile } = useCompanyContext();
    const hookCompanyId = useCompanyId();
    const { companyName, companyDescription } = currentCompanyProfile;
    const [state, setState] = useState<CreateRecruitmentContextState>({
        ...DefaultCreateRecruitmentContextState,
        jobAd: { ...DefaultCreateRecruitmentContextState.jobAd, companyDescription },
    });
    const {
        recruitmentId,
        jobDetails,
        jobAd,
        includedTests,
        testInfos,
        applicantInformation,
        recruitmentCreationOption,
        processingState,
        regenerateAtStep2,
        showErrorDialog,
        testLanguage,
        experienceCriteria,
        experienceCriteriaProcessingState,
        includesExperienceCriteria,
    } = state;

    const assessmentClient = useAssessmentClient();
    const jobTestInfoClient = useJobTestInfoClient();
    const autocompleteOptionsClient = useAutocompleteOptionsClient();
    const settingsClient = useSettingsClient();
    const jobAdClient = useJobAdClient();
    const careerClient = useCareerClient();
    const configuration = useAssessmentSettingsContext();
    const questionsConfigurationClient = useQuestionsConfigurationClient();
    const criteriaClient = useCriteriaClient();

    const shouldGenerateExperienceCriteria = useShouldGenerateExperienceCriteria(jobAd);

    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [locales, setLocales] = useState<string[]>([]);
    const [gettingDataProcessingState, setGettingDataProcessingState] = useState(ProcessingState.Processing);

    const getDemoQuestions = useCallback(
        async (testLang: string) => {
            const demoQuestionsDto = await jobTestInfoClient.getDemoQuestionsAsync(
                jobDetails.jobFunctionId,
                testLang === 'applicant' ? 'en' : testLang,
            );
            const demoQuestions = mapDemoQuestionsDto2Model(demoQuestionsDto);
            return demoQuestions;
        },
        [jobDetails.jobFunctionId],
    );

    const setProcessingState = (value: ProcessingState): void => {
        setState((prevState) => {
            return {
                ...prevState,
                processingState: value,
            };
        });
    };

    useEffect(() => {
        const loadCurrenciesAsync = async () => {
            try {
                const currenciesList = await settingsClient.getCurrenciesAsync();
                setCurrencies(currenciesList);
                const localesList = await questionsConfigurationClient.getTestLocalesAsync();
                setLocales(localesList);
                setGettingDataProcessingState(ProcessingState.Success);
            } catch {
                setGettingDataProcessingState(ProcessingState.Error);
            }
        };
        loadCurrenciesAsync();
    }, []);

    const setContextState = (value: CreateRecruitmentContextState): void => {
        setState(value);
    };

    const setJobAd = (value: JobAd): void => {
        setState((prevState) => {
            return {
                ...prevState,
                jobAd: { ...value },
                regenerateAtStep2: false,
            };
        });
    };

    const setIncludedTests = (value: TestTypes[]): void => {
        setState((prevState) => {
            return {
                ...prevState,
                includedTests: value,
            };
        });
    };

    const setApplicantInformation = (value: ApplicantInformation[]): void => {
        setState((prevState) => {
            return {
                ...prevState,
                applicantInformation: value,
            };
        });
    };

    const setRecruitmentCreationOption = (value: AssessmentStatus): void => {
        setState((prevState) => {
            return {
                ...prevState,
                recruitmentCreationOption: value,
            };
        });
    };

    const setTestLanguage = (value: string): void => {
        setState((prevState) => {
            return {
                ...prevState,
                testLanguage: value,
            };
        });
    };

    const includeTest = (contextState: CreateRecruitmentContextState, testType: TestTypes): boolean => {
        if (contextState.testInfos.findIndex((x) => x.testType === testType) >= 0) {
            contextState.includedTests.push(testType);
            return true;
        }

        return false;
    };

    const updateJobDetails = async (newJobDetails: JobDetails): Promise<void> => {
        setProcessingState(ProcessingState.Processing);
        try {
            const { jobFunctionId } = newJobDetails;
            const testInfosDto = await jobTestInfoClient.getTestInfosAsync(jobFunctionId);
            const newTestInfos = testInfosDto.map((dto) => mapTestInfoDto2Model(dto));
            const equal = jobDetailsDeepEqual(jobDetails, newJobDetails);
            let newJobAd = equal ? jobAd : DefaultJobAd;

            if (!equal) {
                const didJobFunctionChange = jobDetails.jobFunctionId !== newJobDetails.jobFunctionId;
                const { hardSkills, softSkills } = didJobFunctionChange
                    ? await autocompleteOptionsClient.getDefaultSkillsOptions(jobFunctionId)
                    : { hardSkills: jobAd.hardSkills, softSkills: jobAd.softSkills };

                newJobAd = {
                    ...newJobAd,
                    companyDescription: isDefaultLanguage(newJobDetails.jobDescriptionLanguage)
                        ? companyDescription
                        : jobAd.companyDescription,
                    hardSkills,
                    hardSkillsSwitch: didJobFunctionChange ? hardSkills.length > 0 : jobAd.hardSkillsSwitch,
                    softSkills,
                    softSkillsSwitch: didJobFunctionChange ? softSkills.length > 0 : jobAd.softSkillsSwitch,
                };
            }

            const newContextState: CreateRecruitmentContextState = {
                ...state,
                recruitmentId: '',
                jobDetails: newJobDetails,
                jobAd: newJobAd,
                includedTests: [],
                testInfos: newTestInfos,
                applicantInformation: [],
                processingState: ProcessingState.Success,
                regenerateAtStep2: !equal,
            };

            includeTest(newContextState, TestTypes.Personality);
            if (!includeTest(newContextState, TestTypes.Skills)) {
                includeTest(newContextState, TestTypes.Aptitude);
            }
            if (newContextState.jobDetails.directReports !== 'None') {
                includeTest(newContextState, TestTypes.EmotionalIntelligence);
            }

            setContextState(newContextState);
        } catch (e) {
            setProcessingState(ProcessingState.Error);
        }
    };

    const updateRecruitmentAsync = async (): Promise<void> => {
        throw new Error('Method is not implemented');
    };

    const getCareerPageStatus = async (): Promise<boolean> => {
        setProcessingState(ProcessingState.Processing);
        try {
            const careerPageStats = await careerClient.getCareerPageStatsAsync(hookCompanyId);
            setProcessingState(ProcessingState.Success);
            return careerPageStats.publishedDate !== null;
        } catch (e) {
            setProcessingState(ProcessingState.Error);
            throw e;
        }
    };

    const createRecruitmentAsync = async (assessmentStatus: AssessmentStatus, companyId: string): Promise<string> => {
        let id = '';
        setProcessingState(ProcessingState.Processing);
        try {
            const dto = mapCreateRecruitmentContextState2Dto(state, assessmentStatus);
            const result = await assessmentClient.createAssessmentAsync(dto, companyId);
            id = result.id;

            // Only update company description in the settings if:
            // - company description is empty AND
            // - public recruitment is created AND
            // - job ad language is default (English)
            if (
                companyDescription === RTEInitValue &&
                assessmentStatus === 'Public' &&
                isDefaultLanguage(jobDetails.jobDescriptionLanguage)
            ) {
                await updateCompanyProfile({ ...currentCompanyProfile, companyDescription: jobAd.companyDescription });
            }

            setProcessingState(ProcessingState.Success);
        } catch (e) {
            setProcessingState(ProcessingState.Error);
            throw e;
        }

        return id;
    };

    const mapAndSetJobAd = (value: AIJobAdDTO): void => {
        const ja = new JobAdMapper(value, jobAd.companyDescription).map();
        setState((prev: CreateRecruitmentContextState) => ({
            ...prev,
            jobAd: {
                ...prev.jobAd,
                title: ja.title,
                description: ja.description,
                descriptionLen: ja.descriptionLen,
                companyDescription: ja.companyDescription,
            },
            processingState: ProcessingState.Success,
        }));
    };

    const generateJobAdAsync = async (): Promise<void> => {
        // Company description is generated in the following cases:
        // 1. Company description is empty in the settings
        // 2. Company description is not empty and job ad language is not default
        const isSettingsCompanyDescriptionEmpty = extractTextWithNewLinesFromHtml(companyDescription) === '';
        const shouldGenerateCompanyDescription =
            isSettingsCompanyDescriptionEmpty || !isDefaultLanguage(jobDetails.jobDescriptionLanguage);

        try {
            setProcessingState(ProcessingState.Processing);
            const dto: GenerateJobAdDTO = {
                jobTitle: jobDetails.name,
                companyName,
                workArrangement: jobDetails.workArrangement,
                jobLocation: jobDetails.locationDetails.description,
                jobFunction: getJobFunctionName(
                    configuration.assessmentSettings.jobCategories,
                    jobDetails.jobFunctionId,
                ),
                workExperience: jobDetails.workExperience,
                employmentType: jobDetails.employmentType,
                includeCompanyDescription: shouldGenerateCompanyDescription,
                jobAdLanguage: jobDetails.jobDescriptionLanguage.label,
            };
            const response = await jobAdClient.generateJobAdAsync(dto);
            mapAndSetJobAd(response);
        } catch (error) {
            setState((prev) => ({
                ...prev,
                showErrorDialog: 'JobAdGenerationError',
                processingState: ProcessingState.Idle,
            }));
        }
    };

    const improveJobAdAsync = async (tone: WritingTone, title: string, jobDescription: string, companyDesc: string) => {
        try {
            setState((prev) => ({
                ...prev,
                processingState: ProcessingState.Processing,
                jobAd: { ...prev.jobAd, title: ' ' }, // imitate empty field without causing Required error for title field
            }));
            const plainJobDescription = extractTextWithNewLinesFromHtml(jobDescription);
            const plainCompanyDescription = extractTextWithNewLinesFromHtml(companyDesc);

            // Company description should be included into improvement if:
            // 1. Company description is empty in the settings
            // 2. Company description is not empty and job ad language is not default
            const isSettingsCompanyDescriptionEmpty = companyDescription === RTEInitValue;
            const includeCompanyDescription =
                isSettingsCompanyDescriptionEmpty || !isDefaultLanguage(jobDetails.jobDescriptionLanguage);

            const dto: ImproveJobAdDTO = {
                title,
                jobAd: plainJobDescription,
                tone,
                jobAdLanguage: jobDetails.jobDescriptionLanguage.label,
                companyDescription: includeCompanyDescription ? plainCompanyDescription : undefined,
            };
            const response = await jobAdClient.improveJobAdAsync(dto);
            mapAndSetJobAd(response);
        } catch (error) {
            setState((prev) => ({
                ...prev,
                showErrorDialog: 'JobAdImproveError',
                processingState: ProcessingState.Idle,
                jobAd: { ...prev.jobAd, title }, // in case there is an error - rollback title to the initial value
            }));
        }
    };

    const closeDialog = () => {
        setState((prev) => {
            return {
                ...prev,
                showErrorDialog: undefined,
            };
        });
    };

    useEffect(() => {
        const generateExperienceCriteria = async () => {
            setState((p) => ({ ...p, experienceCriteriaProcessingState: ProcessingState.Processing }));

            try {
                const dto: GenerateExperienceCriteriaDTO = createGenerateExperienceCriteriaDTO(jobAd, jobDetails);
                const response = await criteriaClient.generateCriteriaAsync(dto);
                setState((prev: CreateRecruitmentContextState) => ({
                    ...prev,
                    experienceCriteria: response.criteria,
                    experienceCriteriaProcessingState: ProcessingState.Success,
                    includesExperienceCriteria: true,
                }));
            } catch (error) {
                setState((prev) => ({ ...prev, experienceCriteriaProcessingState: ProcessingState.Error }));
            }
        };

        if (shouldGenerateExperienceCriteria) {
            generateExperienceCriteria();
        }
        // explicitly should only contain shouldGenerateExperienceCriteria,
        // when edited but the number stayed the same, this shouldn't trigger (covered by cy in CriteriaAIGeneration)
    }, [shouldGenerateExperienceCriteria]);

    const resetExperienceCriteriaProcessingState = () => {
        setState((prev) => ({ ...prev, experienceCriteriaProcessingState: ProcessingState.Idle }));
    };

    const setExperienceCriteriaForm: ICreateRecruitmentContext['setExperienceCriteriaForm'] = (newValue) => {
        setState((p) => ({
            ...p,
            experienceCriteria: newValue.experienceCriteria,
            includesExperienceCriteria: newValue.includesExperienceCriteria,
        }));
    };

    return (
        <CreateRecruitmentContextProvider
            value={{
                recruitmentId,
                jobDetails,
                jobAd,
                includedTests,
                testInfos,
                applicantInformation,
                recruitmentCreationOption,
                processingState,
                regenerateAtStep2,
                showErrorDialog,
                testLanguage,
                includesExperienceCriteria,
                experienceCriteria,
                experienceCriteriaProcessingState,

                currencies,
                locales,
                gettingDataProcessingState,

                setContextState,
                setJobAd,
                setIncludedTests,
                setRecruitmentCreationOption,
                setApplicantInformation,
                setProcessingState,
                setTestLanguage,
                updateJobDetails,
                updateRecruitmentAsync,
                getCareerPageStatus,
                createRecruitmentAsync,
                generateJobAdAsync,
                improveJobAdAsync,
                closeDialog,
                getDemoQuestions,
                setExperienceCriteriaForm,
                resetExperienceCriteriaProcessingState,
            }}
        >
            {children}
        </CreateRecruitmentContextProvider>
    );
};

export function useCreateRecruitmentContext() {
    const context = useContext(CreateRecruitmentContext);
    if (!context) {
        throw new Error('useCreateRecruitmentContext must be used within the CreateRecruitmentContext.Provider');
    }
    return context;
}

import { ChevronRightIcon } from '@talentmesh/icons';
import React from 'react';
import ProcessingState from '../../../../../../Models/ProcessingState';
import UIStrings from '../../../../../../Utils/UIStrings';
import NextStepButton from '../../../Components/NextStepButton';
import { StepperNavigationButtons } from '../../../Components/StepperNavigationButtons';
import { useCreateRecruitmentContext } from '../../../Create/Contexts/CreateRecruitmentContext';
import StepProps from '../../StepProps';

function IdentifyExperienceStepNavigation({ activeStep, handleBack }: StepProps): JSX.Element {
    const { experienceCriteriaProcessingState } = useCreateRecruitmentContext();

    return (
        <StepperNavigationButtons
            activeStep={activeStep}
            handleBack={handleBack}
            nextButtonComponent={
                <NextStepButton
                    loading={experienceCriteriaProcessingState === ProcessingState.Processing}
                    endIcon={<ChevronRightIcon />}
                >
                    {UIStrings.Next}
                </NextStepButton>
            }
        />
    );
}

export default IdentifyExperienceStepNavigation;

import React from 'react';
import { Paper, PaperProps } from '@talentmesh/core';

function TabPaper({ children, ...props }: PaperProps): JSX.Element {
    return (
        <Paper
            elevation={0}
            sx={{
                borderTopLeftRadius: 0,
                padding: 2.5,
            }}
            {...props}
        >
            {children}
        </Paper>
    );
}

export default TabPaper;

import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import User, { DefaultUser } from '../Models/User';
import { SetupAccountDTO } from '../DTO/Users/SetupAccountDTO';
import { useUsersClient } from '../Hooks/ClientHooks';
import ProvisionStatusDTO, { DefaultProvisionStatusDTO } from '../DTO/Users/ProvisionStatusDTO';
import CompanyMembershipDTO from '../DTO/Users/CompanyMembershipDTO';

type UserContextType = {
    user: User;
    isLoading: boolean;
    updateUser: (input: User) => Promise<User>;
    setupAccount: (dto: SetupAccountDTO) => Promise<User>;
    error?: Error;
    provisionStatus: ProvisionStatusDTO;
    tryProvisionAccount: () => Promise<void>;
    provisioningInProcess: boolean;
    getUserAsync: () => Promise<void>;
    memberships: Array<CompanyMembershipDTO>;
};

export const UserContext = createContext<UserContextType | undefined>(undefined);

const UserContextProvider = UserContext.Provider;

interface UserProviderProps {
    children: ReactNode;
}

export const UserProvider = ({ children }: UserProviderProps): JSX.Element => {
    const [talentMeshUser, setTalentMeshUser] = useState<User>(DefaultUser);
    const [provisionStatus, setProvisionStatus] = useState<ProvisionStatusDTO>(DefaultProvisionStatusDTO);
    const [memberships, setMemberships] = useState<Array<CompanyMembershipDTO>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [provisioningInProcess, setProvisioningInProcess] = useState(false);
    const [error, setError] = useState<Error | undefined>(undefined);
    const { user, isAuthenticated } = useAuth0();
    const client = useUsersClient();

    const updateUser = async (input: User): Promise<User> => {
        const response = await client.updateUserAsync(input);
        setTalentMeshUser(response);
        return response;
    };

    const refetchCompanies = async () => {
        const retrievedMemberships = await client.getMembershipsAsync();
        setMemberships(retrievedMemberships);
    };

    const setupAccount = async (dto: SetupAccountDTO) => {
        const response = await client.setupAccountAsync(dto);
        setTalentMeshUser(response);
        await refetchCompanies();
        return response;
    };

    const canBeProvisioned = () => {
        return provisionStatus.recruiterId && !provisionStatus.isProvisioned;
    };

    const isAccountInfoAvailable = () => {
        return talentMeshUser?.firstName && talentMeshUser?.lastName;
    };

    const provisionAccount = async () => {
        setProvisioningInProcess(true);
        try {
            await client.setProvisionStatusAsync();
            setProvisionStatus({
                recruiterId: provisionStatus.recruiterId,
                isProvisioned: true,
            });
        } finally {
            setProvisioningInProcess(false);
        }
    };

    const tryProvisionAccount = async () => {
        if (canBeProvisioned() && user?.email_verified && isAccountInfoAvailable()) {
            provisionAccount();
        }
    };

    const getUserAsync = async () => {
        try {
            const response = await client.getUserAsync();
            setTalentMeshUser(response);
            const provisionStatusDTO = await client.getProvisionStatusAsync();
            setProvisionStatus(provisionStatusDTO);
            await refetchCompanies();
        } catch (exception) {
            setError(exception as Error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (isAuthenticated && user) {
            const get = async () => {
                await getUserAsync();
            };
            get();
        } else {
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    return (
        <UserContextProvider
            value={{
                user: talentMeshUser,
                isLoading,
                updateUser,
                setupAccount,
                error,
                provisionStatus,
                tryProvisionAccount,
                provisioningInProcess,
                getUserAsync,
                memberships,
            }}
        >
            {children}
        </UserContextProvider>
    );
};

export function useUserContext() {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUserContext must be used within the UserContext.Provider');
    }
    return context;
}

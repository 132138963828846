import React, { createContext, ReactNode, useContext } from 'react';

export enum RecruitmentActionTypes {
    Create = 'create',
    Edit = 'edit',
}

const ModifyRecruitmentContext = createContext<RecruitmentActionTypes>(RecruitmentActionTypes.Create);

const ModifyRecruitmentContextProvider = ModifyRecruitmentContext.Provider;

interface ModifyRecruitmentContextProviderProps {
    action: RecruitmentActionTypes;
    children: ReactNode;
}

export const ModifyRecruitmentProvider = ({ action, children }: ModifyRecruitmentContextProviderProps): JSX.Element => {
    return <ModifyRecruitmentContextProvider value={action}>{children}</ModifyRecruitmentContextProvider>;
};

export function useModifyRecruitmentContext() {
    const context = useContext(ModifyRecruitmentContext);
    if (!context) {
        throw new Error('useModifyRecruitmentContext must be used within the ModifyRecruitmentContext.Provider');
    }
    return context;
}

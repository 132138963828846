import React from 'react';
import { Button } from '@talentmesh/core';
import UIStrings from '../../../../Utils/UIStrings';
import { useInviteCandidateContext } from '../Contexts/InviteCandidateContext';

function GoHomeButton(): JSX.Element {
    const { navigateToMyRecruitments } = useInviteCandidateContext();
    return (
        <Button
            onClick={navigateToMyRecruitments}
            variant="contained"
            sx={{
                justifySelf: 'flex-end',
            }}
        >
            {UIStrings.GoToMyRecruitments}
        </Button>
    );
}

export default GoHomeButton;

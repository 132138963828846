import React from 'react';
import withSignupChecks from '../../../../Routing/withSignupChecks';
import withTalentMeshUserRequired from '../../../../Routing/withTalentMeshUserRequired';
import { EditRecruitmentProvider } from './Contexts/EditRecruitmentContext';
import EditRecruitmentPageContent from './EditRecruitmentPageContent';
import withGooglePlacesAPICheck from '../../../../Routing/withGooglePlacesAPICheck';
import { ModifyRecruitmentProvider, RecruitmentActionTypes } from '../Contexts/ModifyRecruitmentContext';
import withCompanyProfileRequired from '../../../../Routing/withCompanyProfileRequired';

const EditRecruitmentPage = () => {
    return (
        <ModifyRecruitmentProvider action={RecruitmentActionTypes.Edit}>
            <EditRecruitmentProvider>
                <EditRecruitmentPageContent />
            </EditRecruitmentProvider>
        </ModifyRecruitmentProvider>
    );
};

export default withCompanyProfileRequired(
    withTalentMeshUserRequired(withSignupChecks(withGooglePlacesAPICheck(EditRecruitmentPage))),
);

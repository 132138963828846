import { generatePath } from 'react-router';
import useQuery from '../../../../Hooks/UseQuery';
import RoutePath from '../../../../Routing/RoutePath';
import UIStrings from '../../../../Utils/UIStrings';
import useCompanyId from '../../../../Hooks/UseCompanyId';

const useAssessmentCurrentTab = () => {
    const tab = useQuery('filter');
    const companyId = useCompanyId();
    return tab === UIStrings.Closed.toLowerCase()
        ? generatePath(RoutePath.AssessmentsClosed, { companyId })
        : generatePath(RoutePath.AssessmentsActive, { companyId });
};

export default useAssessmentCurrentTab;

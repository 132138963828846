import React from 'react';
import { RoundButtonTab, Tabs } from '@talentmesh/core';
import { useCandidatesResultsContext } from '../Contexts/CandidateResultsContext';
import {
    getTabHeaderByTestType,
    getCandidateResultsTabValue,
    isTestCompleted,
    hasCandidateOnboared,
} from '../Utils/ResultsUtils';
import { mainTestTypesRenderingOrder } from '../../Utils/Utils';
import { TestTypes } from '../../../../Models/Configuration';
import UIStrings from '../../../../Utils/UIStrings';

function ResultsTab(): JSX.Element {
    const { results, activeTestDetailsTab, setActiveTestDetailsTab } = useCandidatesResultsContext();
    const handleChange = (_: React.SyntheticEvent, newValue: TestTypes) => {
        setActiveTestDetailsTab(newValue);
    };

    return (
        <Tabs value={activeTestDetailsTab} onChange={handleChange}>
            {mainTestTypesRenderingOrder.map((testType) => {
                if (results.includedTests.indexOf(testType) > -1) {
                    return (
                        <RoundButtonTab
                            key={testType}
                            label={getTabHeaderByTestType(testType)}
                            value={getCandidateResultsTabValue(testType)}
                            disabled={!isTestCompleted(results, testType)}
                        />
                    );
                }
                return null;
            })}
            {results.includesExperienceCriteria && (
                <RoundButtonTab
                    label={UIStrings.Experience}
                    value={getCandidateResultsTabValue('experience')}
                    disabled={!hasCandidateOnboared(results.candidateStatus)}
                />
            )}
            <RoundButtonTab
                key={TestTypes.None}
                label={UIStrings.CVAndApplicantInformation}
                value={getCandidateResultsTabValue('cvInfo')}
            />
        </Tabs>
    );
}

export default ResultsTab;

import React from 'react';
import TabByTestTypeSwitcher from './Tabs/TabByTestTypeSwitcher';
import CTMonitoringPanelByTestType from './TestMonitoring/CTMonitoringPanelByTestType';
import WideTabPanel from '../../../../Components/Tab/WideTabPanel';
import DetailsTab from './Tabs/DetailsTab';
import { mainTestTypesRenderingOrder } from '../../Utils/Utils';
import { useCandidatesResultsContext } from '../Contexts/CandidateResultsContext';
import { getCandidateResultsTabValue, isTestCompleted } from '../Utils/ResultsUtils';
import ExperienceTab from './Tabs/ExperienceTab';

function ResultsTabContent(): JSX.Element {
    const { results, activeTestDetailsTab } = useCandidatesResultsContext();

    return (
        <>
            {mainTestTypesRenderingOrder.map((testType) => {
                if (isTestCompleted(results, testType)) {
                    return (
                        <WideTabPanel
                            key={testType}
                            tabKey={getCandidateResultsTabValue(testType)}
                            value={activeTestDetailsTab}
                        >
                            <TabByTestTypeSwitcher testType={testType} />
                            <CTMonitoringPanelByTestType testType={testType} {...results} />
                        </WideTabPanel>
                    );
                }
                return null;
            })}
            <WideTabPanel tabKey={getCandidateResultsTabValue('experience')} value={activeTestDetailsTab}>
                <ExperienceTab />
            </WideTabPanel>
            <WideTabPanel tabKey={getCandidateResultsTabValue('cvInfo')} value={activeTestDetailsTab}>
                <DetailsTab />
            </WideTabPanel>
        </>
    );
}

export default ResultsTabContent;

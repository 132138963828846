import React from 'react';
import { Button } from '@talentmesh/core';
import { generatePath } from 'react-router';
import UIStrings from '../../../Utils/UIStrings';
import RoutePath from '../../../Routing/RoutePath';
import useTalentMeshHistory from '../../../Routing/useTalentMeshHistory';

interface RecruitmentCreatedNotificationActionProps {
    recruitmentId: string;
    companyId: string;
    onClose: () => void;
}

const RecruitmentCreatedNotificationAction = ({
    recruitmentId,
    companyId,
    onClose,
}: RecruitmentCreatedNotificationActionProps) => {
    const history = useTalentMeshHistory();

    const fromUrl = generatePath(RoutePath.CompanyRoot, { companyId });

    const handleClick = () => {
        const url = generatePath(RoutePath.AttractApplicantsJobBoards, { companyId, recruitmentId });
        history.push(url, { allowDialog: true, from: fromUrl });
        onClose();
    };

    return (
        <Button fullWidth size="small" variant="contained" onClick={handleClick}>
            {UIStrings.AttractApplicants}
        </Button>
    );
};

export default RecruitmentCreatedNotificationAction;

import { FormatOrdinal } from '@talentmesh/core';
import { TestTypes } from '../Models/Configuration';

/* eslint-disable @typescript-eslint/lines-between-class-members */
export default class UIStrings {
    static readonly Auth0OidcErrorMessage =
        'Password login via OIDC-conformant clients with externally-hosted login pages is unsupported. Alternatively, login could have been initiated from the wrong place (e.g., a bookmark).';
    static readonly DueToSecurityReasonsWeCouldNotAuthenticateYourRequest =
        'Due to security reasons, we could not authenticate the log in or sign up action.';
    static readonly PleaseTryAgain = 'Please try again.';
    static readonly LogInToTalentmesh = 'Log in to TalentMesh';
    static readonly GetFreeTrial = 'Get free trial';
    static readonly BookmarkingThisPageUseThisUrl = 'Bookmarking the login page? Use this URL';
    static readonly CopyToClipboard = 'Copy to clipboard';
    static readonly LinkCopied = 'Link copied!';
    static readonly PageNotFound = 'Page Not Found';
    // Recruitments Overview
    static readonly CreateRecruitment = 'Create recruitment';
    static readonly PublishRecruitment = 'Publish recruitment';

    // steppers
    static readonly JobDetails = 'Job details';
    static readonly JobAd = 'Job ad';
    static readonly ApplicantQuestions = 'Applicant questions';
    static readonly GeneralInformation = 'General information';
    static readonly MotivationalInformation = 'Motivational information';
    static readonly StartupInformation = 'Information relevant for start-up companies';
    static readonly ApplicantInformation = 'Applicant information';
    static readonly IdentifyExperience = 'Identify experience';
    static readonly PostJob = 'Post Job';

    static readonly PreviewJobAd = 'Preview Job ad';
    static readonly ThisIsAPreviewOfHowJobAdPostMayAppear = 'This is a preview of how your job post may appear.';
    static readonly PleaseNoteLiveVersionMayBeDifferent =
        ' Please note that the live version may have slight variations.';

    static readonly MyRecruitments = 'My recruitments';
    static readonly ProvideKeyJobDetails = 'Provide key job details';
    static readonly SetupYourProfile = 'Set up your profile';
    static readonly ApplicantsWillSeeThisInEmails = 'Applicants will see this information in emails and assessments';

    static readonly YourEmailHasBeenVerified = 'Your email has been verified';
    static readonly YouCanNowLoginToTalentMesh = 'You can now log in to TalentMesh.';

    static readonly SaveAndContinue = 'Save and continue';

    static readonly FirstName = 'First name*';
    static readonly LastName = 'Last name*';

    static readonly Required = 'Required';
    static readonly Logout = 'Logout';
    static readonly Settings = 'Settings';
    static readonly SwitchCompany = 'Switch company';

    static readonly Active = 'Active';
    static readonly Closed = 'Closed';

    static readonly AuthenticationError = 'Authentication Error.';
    static readonly OopsError = 'Oops! An error occurred.';
    static readonly NotAuthorized = 'Not Authorized';
    static readonly NotAuthorizedToViewPage = 'You are not authorized to view this page';
    static readonly NoAccessToOrganization = 'You do not have access to the company you are trying to access';
    static readonly SomethingWentWrong = 'Something went wrong';
    static readonly SomethingWentWrongLong =
        'Something went wrong and we could not process your last action. Please check your network connection and try again.';
    static readonly TryAgainLater = 'Please try again later';
    static readonly TryAgain = 'Try again';
    static readonly JobTitle = 'Job title';
    static readonly WorkArrangement = 'Work arrangement';
    static readonly JobLocation = 'Job location';
    static readonly EmployeeLocation = 'Employee location';
    static readonly FullyRemote = 'Fully Remote';
    static readonly DirectReports = 'Direct reports';
    static readonly YearlySalaryRange = 'Yearly Salary Range';
    static readonly Currency = 'Currency';
    static readonly DoNotDisplaySalary = 'Do not display salary';
    static readonly JobDescriptionLanguage = 'Job description language';

    static readonly JobTitleDescription =
        'Please enter the job title for the position you are hiring for. We recommend that the job title is consistent with the actual title an applicant will hold if hired. The job title will be displayed on your job posting.';

    // job location field
    static readonly OnLocationTooltipTitle = 'Job location for on-location and hybrid jobs';
    static readonly OnLocationTooltipDescription =
        'For on-location and hybrid jobs, please select the city where the office is located.';
    static readonly RemoteTooltipTitle = 'Employee location for fully remote jobs';
    static readonly RemoteTooltipDescription =
        'For fully remote jobs, please select the country or city where the employees should be located.';

    static readonly TypeCity = 'Type city';
    static readonly TypeCountryOrCity = 'Type country or city';
    static readonly NoLocations = 'No locations';
    static readonly Loading = 'Loading...';
    static readonly PoweredBy = 'powered by';

    static readonly CreateJobAd = 'Create job ad';
    static readonly ProvideInformationForTheJobAd = 'Provide information for the job ad';
    static readonly DescribeTheJob =
        'Describe the job as well as the skills required to succeed, by editing the draft job ad provided below, or using AI to improve the draft.';
    static readonly JobAdTitle = 'Job ad title';
    static readonly SkillsRequiredToSucceed = 'Skills required to succeed';
    static readonly HardSkills = 'Hard skills';
    static readonly HardSkillsUpper = 'Hard Skills';
    static readonly ThisSectionIncludesTheSkillRequiredToSucceed = (type: string) =>
        `This section includes the ${type} required to succeed in this role. We have already added recommended skills, but you can remove and add skills to better fit your unique hiring situation. We recommend to only include the key skills, but you can include a total of 10 skills badge`;
    static readonly AddSkills = 'Add skills';
    static readonly SoftSkills = 'Soft skills';
    static readonly SoftSkillsPersonalityTraits = 'Soft skills (personality traits)';
    static readonly TypePersonalityTraits = 'Type personality traits';
    static readonly JobDescription = 'Job description';
    static readonly CustomizeTheJobPostTitle = 'Customize the job post title for better candidate attraction';
    static readonly CharactersCounterWithLimit = (value: number, limit: number) => `${value}/${limit} characters`;
    static readonly RecommendedCharacters = 'Recommended 700 to 2,000 characters.';

    static readonly AddApplicant = 'Add applicant';

    static readonly SkillsTestsForJobFunctionName = (jobFunctionName: string): string =>
        `Skills tests for ${jobFunctionName}`;
    static readonly SoftAndHardSkillsTests = 'Soft and hard skills tests';
    static readonly SelectedForYourHiringSituation = 'selected for your unique hiring situation';

    static readonly RequestAdditionalApplicantInformation = 'Request additional applicant information';
    static readonly HowDoYouWantToPost = 'How do you want to post your job?';

    static readonly IdentifyApplicantExperience = 'Identify applicant experience';
    static readonly UseTalentmeshAIToIdentifyAndSetApplicantCriteria = `In this job creation mode, recruiters can use Talentmesh AI to identify and set applicant criteria. This helps recruiters better evaluate and score candidates, ensuring a more precise match to the role's requirements.`;

    static readonly Criteria = (index: number) => `Criteria ${index}`;
    static readonly AddCriteria = 'Add criteria';
    static readonly ExperienceCriteriaStatus = (enabled: boolean) =>
        `Experience criteria ${enabled ? 'enabled' : 'disabled'}`;

    static readonly ErrorGeneratingExperienceCriteria = 'Error generating experience criteria';
    static readonly ThereWasAnErrorGeneratingTheExperienceCriteria =
        'There was an error generating the experience criteria. Please fill in manually.';

    static readonly AttractApplicants = 'Attract applicants';
    static readonly For = 'for';
    static readonly EasilyAttractTopApplicantsThrough =
        'Easily attract top applicants through job boards, career pages, and invitation links & email.';

    static readonly SendInvitationButton = 'Send invitations';
    static readonly Next = 'Next';
    static readonly Previous = 'Previous';
    static readonly Update = 'Update';

    static readonly ApplicantEmail = "Applicant's email*";
    static readonly UseAsInternalBenchmark = 'Use as internal benchmark';
    static readonly WorkExperience = 'Work experience';
    static readonly EmploymentType = 'Employment type';

    static readonly Email = 'Email';

    static readonly StillHavingIssues = 'Still having issues?';
    static readonly ForAssistance = 'for assistance.';

    static readonly QuickSummaryRecommendationStep =
        'Use checkboxes to select or deselect tests. The tests are designed to qualify the applicant skills required to succeed in the specific role. The tests are automatically administered to all applicants, so you can understand the talent of your entire applicant pool. Learn more';
    static readonly QuickSummaryRequestInformationStep =
        'Toggle on the information you would like applicants to provide';
    static readonly QuickSummaryCreateStep =
        'The key details for your open job will help us identify the skills required to succeed in the role, which we will use create a customized assessment and job ad.';
    // 'Describe the job as well as the skills required to succeed, by editing the draft job ad provided below, or using AI to improve the draft. ';
    static readonly GoToMyRecruitments = 'Go to my recruitments';

    static readonly Save = 'Save';
    static readonly Publish = 'Publish';

    static readonly FullscreenMode = 'Full screen mode';

    static readonly Invited = 'Invited';
    static readonly TotalApplicants = 'Total Applicants';
    static readonly Status = 'Status';

    static readonly PaymentMethod = 'Payment method';

    static readonly PayNow = 'Pay now';
    static readonly Name = 'Name';
    static readonly Applicant = 'Applicant';
    static readonly Aptitude = 'Aptitude';
    static readonly EmotionalIntelligence = 'Emotional intelligence';
    static readonly Personality = 'Personality';
    static readonly SearchRecruitments = 'Search recruitments';
    static readonly Hired = 'Hired';
    static readonly Skills = 'Skills';
    static readonly RejectMultipleApplicants = 'Reject multiple applicants';
    static readonly EditEmailTemplate = 'Edit email template';
    static readonly DownloadReport = 'Download report';
    static readonly MarkAsInterviewing = 'Mark as interviewing';
    static readonly Interviewing = 'Interviewing';
    static readonly Disabled = 'Disabled';
    static readonly Enabled = 'Enabled';
    static readonly NA = 'N/A';
    static readonly MarkAsHired = 'Mark as Hired';
    static readonly Reject = 'Reject';
    static readonly RejectApplicant = 'Reject Applicant';
    static readonly AssessmentOpened = 'Assessment opened';
    static readonly AssessmentOngoing = 'Assessment ongoing';
    static readonly AssessmentCompleted = 'Assessment completed';
    static readonly AssessmentRetaken = 'Assessment retaken';
    static readonly MarkAsRejected = 'Mark as rejected';
    static readonly ApplicantsOverview = 'Applicants Overview';
    static readonly Open = 'Open';
    static readonly Rejected = 'Rejected';
    static readonly RejectApplicants = 'Reject applicants';
    static readonly ReadyToCloseThisRecruitment = 'Ready to close this recruitment?';
    static readonly CloseRecruitmentProceed1 =
        "If you've made a hire for this position, please make sure to mark the applicant(s) as hired before you close the recruitment.";
    static readonly CloseRecruitmentProceed2 =
        'Once the recruitment is closed, it will be moved to the closed recruitment tab under the my recruitments page, and you will no longer be able to add applicants or change applicant statuses.';
    static readonly FilterByApplicantsName = 'Filter by applicants name';
    static readonly Cancel = 'Cancel';
    static readonly InterviewInvitationEmailTemplate = 'Interview invitation email template';
    static readonly Interview = 'Interview';
    static readonly RejectDialogNote =
        'Click on the button below to be redirected to your account settings to edit your email templates.';
    static readonly InvitationForInterview = 'Invitation for interview';
    static readonly UNDO = 'UNDO';
    static readonly ClearAll = 'Clear all';

    static readonly GeneralSettingsTab = 'General';
    static readonly Notifications = 'Notifications';
    static readonly PaymentSettingsTab = 'Payment';
    static readonly EditEmailTemplateSettingsTab = 'Edit Email Templates';
    static readonly TeamMembers = 'Team Members';

    static readonly AccountDataSuccessfullyUpdated = 'Your account data has been successfully updated';
    static readonly BillingDataSuccessfullyUpdated = 'Your billing address has been successfully updated';
    static readonly EmailTemplateSuccessfullyUpdated = 'The email template has been successfully updated';

    static readonly Minutes = 'minutes';
    static readonly Mins = 'mins';
    static readonly ShortMinute = 'min';
    static readonly Questions = 'questions';

    static readonly ContactUsCamel = 'contact us';
    static readonly ContactUsCapitalized = 'Contact us';
    static readonly ContactSupport = 'Contact support';

    static readonly ContactSupportSection =
        'Have issues with your account? Contact our support team if you want to speak to someone or delete your account.';

    static readonly SomeoneScore = (name: string): string => `${name}'s score`;
    static readonly AverageOfInvited = 'Average of all';
    static readonly InvitedApplicants = 'invited applicant(s)';
    static readonly InternalBenchmark = 'Internal Benchmark';
    static readonly InternalBenchmarks = 'internal benchmark(s)';
    static readonly EmailTemplates = 'Email templates';
    static readonly ManageInterviewInvitation =
        'Manage your interview invitation and rejection email templates here. All changes will apply immediately to future emails composed from TalentMesh.';
    static readonly InterviewInvitationEmail = 'Interview invitation email';
    static readonly RejectionEmail = 'Rejection email';
    static readonly ApplicantNameTooltip = "Applicant's name will be automatically filled for each invitation.";
    static readonly RecruiterNameTooltip = 'You can update your name and company in the general settings tab.';
    static readonly SaveTemplate = 'Save template';
    static readonly PreviewEmail = 'Preview email';
    static readonly PreviewInvitationEmail = 'Preview invitation email';
    static readonly PreviewRejectionEmail = 'Preview rejection email';
    static readonly ApplicantsNamePlaceholder = "{APPLICANT'S NAME}";
    static readonly Back = 'Back';
    static readonly InvitationEmail = 'Invitation Email';
    static readonly UsesTalentMeshBestPractices = "uses TalentMesh's best practice invitation email";

    static readonly Snapshot = 'Snapshot';
    static readonly Of = 'of';

    static readonly TestNotSupportedError = (type: TestTypes): string =>
        `Test type ${type} is not supported within this components`;
    static readonly TestMonitoringExplanation =
        "TalentMesh's applicant test monitoring features let you verify that the right applicants took the tests under the right conditions. Snapshots are taken randomly throughout the test.";

    static readonly OverallScore = 'Overall score';
    static readonly ScreenSnapshots = 'Screen snapshots';

    static readonly Webcam = 'Webcam';
    static readonly ApplicantTestMonitoring = 'Applicant test monitoring';
    static readonly WebcamSnapshots = 'Webcam snapshots';

    static readonly BillingTo = 'Name/Company name*';
    static readonly Country = 'Country';
    static readonly CreditCard = 'Credit card*';
    static readonly AddressLine1 = 'Address line 1*';
    static readonly AddressLine2 = 'Address line 2 (optional)';
    static readonly City = 'City*';
    static readonly State = 'State*';
    static readonly Zip = 'Zip*';

    static readonly BillingAddress = 'Billing address';
    static readonly AddBillingAddress = 'Add billing address';
    static readonly Edit = 'Edit';

    static readonly PaymentDetails = 'Payment details';

    static readonly Taxes = 'Taxes';
    static readonly FeeAmount = 'Fee amount';
    static readonly AmountDueToday = 'Amount due today';
    static readonly TotalAmount = 'Total amount';
    static readonly And = 'and';

    static readonly TestValidity = 'Test validity';
    static readonly TestValidityScore = 'Test validity score';
    static readonly TestValidityVeryGood = 'Very good';
    static readonly TestValidityGood = 'Good';
    static readonly TestValidityModerate = 'Moderate';
    static readonly TestValidityPoor = 'Poor';
    static readonly TestValidityVeryPoor = 'Very Poor';
    static readonly TestResultsCanBeReliedOn = 'Test results can be relied on';
    static readonly UseWithCaution = 'Some impression management, use with caution';
    static readonly ImpressionManagement = 'Impression management, rely on other tests';

    static readonly PaymentSuccessful = 'Payment Successful!';
    static readonly PaymentSuccessMessage1 = 'The payment has been done successfully.';
    static readonly PaymentSuccessMessage2 = 'Thanks for being there with us.';

    static readonly PaymentFailed = 'Payment Failed!';
    static readonly PaymentFailedMessage1 = 'The payment was unsuccessful due to an abnormality.';
    static readonly PaymentFailedMessage2 = 'Please try again later or use another payment method.';

    static readonly PaymentReceived = `We've received your payment!`;
    static readonly PaymentSuccessMessagePart1 = 'Your account will now be';
    static readonly PaymentSuccessMessagePart2 = 'upgraded to the';
    static readonly PaymentSuccessMessagePart3 = 'plan. You will also receive an email receipt of your payment.';

    static readonly ContinueToTalentMesh = 'Continue to TalentMesh';
    static readonly AccountSettings = 'Account settings';

    static readonly OopsSomethingWentWrong = 'Oops! Something went wrong';
    static readonly PleaseRefresh = 'Please refresh the page or repeat your last action to try again.';
    static readonly PleaseEnterValidEmailAddress = 'Please enter a valid email address';

    static readonly ExitAndDiscard = 'Exit and discard';

    static readonly CharacteristicsIncluding = 'characteristics, including';
    static readonly Modules = 'modules';
    static readonly CognitiveAbilityModules = 'cognitive ability modules';

    static readonly ApplicantTestMonitoringExplanations1 =
        "TalentMesh's applicant test monitoring lets you verify that the right applicants take the tests under the right conditions.";
    static readonly ApplicantTestMonitoringExplanations2 =
        'Applicants are encouraged to turn on features such as snapshots of their screen, webcam photos, and full-screen mode.';
    static readonly ApplicantTestMonitoringExplanations3 =
        "You can review the details on applicants' result pages after they've submitted their assessments.";
    static readonly SendRejectionEmail = 'Send rejection email';
    static readonly RejectApplicantDialogHeader1 = 'Reject';
    static readonly RejectApplicantDialogBody1 =
        'Click on "Send rejection email" to send the rejection email shown on the right.';
    static readonly RejectApplicantDialogBody2 = 'Alternatively, click "Mark as rejected" to update';
    static readonly RejectApplicantDialogBody3 = "'s status to rejected, without sending an email.";
    static readonly ForAnInterview = 'for an interview';

    static readonly ClickOn = 'Click on';
    static readonly ComposeInvitationEmail = 'Compose invitation email';
    static readonly InterviewDialogBody3 = 'to open the invitation template in your email';
    static readonly InterviewDialogBody4 = 'Alternatively, simply click "Mark as Interviewing" to change';
    static readonly InterviewDialogBody5 = "'s status";

    static readonly InterviewConfirmDialogHeader1 = 'Have you sent the interview invitation email to';

    static readonly InterviewConfirmDialogBody1 = 'Change';
    static readonly InterviewConfirmDialogBody2 = `'s status to "Interviewing", by clicking the below button, after you have successfully sent the invitation email.`;
    static readonly InvitationHasBeenSentTo = (firstName: string, lastname: string) =>
        `Invitation has been sent to ${firstName} ${lastname}.`;

    static readonly Hire = 'Hire';

    static readonly HaveYouHired = 'Have you hired';

    static readonly HireDialogBody1 = 'Click "Mark as hired" to update';
    static readonly HireDialogBody2 = "'s status to hired.";

    static readonly HasBeenMarkedAsInterviewing = 'has been marked as interviewing';
    static readonly HasBeenMarkedAsHired = 'has been marked as hired';

    static readonly CongratulationsOnTheHire = 'Congratulations on the hire!';
    static readonly YouAreRequiredToMakePayment = 'You are now required to make payment for using our services.';
    static readonly PaymentIs10Percent = 'Payment is 7% of the annual salary.';
    static readonly InTheEventTheApplicantLeaves =
        'In the event the applicant leaves within 6 months, please write us and a replacement hire on our system will be free of charge.';

    static readonly KeepRecruitmentOpen = 'Keep recruitment open';
    static readonly ContinueToPayment = 'Continue to payment';

    static readonly ScreenSnapshotsDisabled = 'Screen snapshots disabled';
    static readonly ScreenSnapshotsIsMissing = 'Screen snapshot not available';
    static readonly WebcamDisabled = 'Webcam disabled';

    static readonly InviteCoWorkersAsInternalBenchmark = 'Invite co-workers as internal benchmark';
    static readonly DidYouKnow = 'Did you know?';
    static readonly InviteCoWorkersAsInternalBenchmarkExplanation1 = 'It is common practice to have your';
    static readonly InviteCoWorkersAsInternalBenchmarkExplanation2 =
        'co-workers take the same talent assessment as job applicants. This way, you can use their scores as internal benchmarks to evaluate the results of potential hires.';
    static readonly ApplicantAlreadyExistsInCurrentRecruitment = 'You have already added this applicant';
    static readonly YourInvitationsAreSent = 'Your invitations are sent';
    static readonly YouWillBeNotifiedByEmail =
        'You will be notified by email once an applicant has completed the assessment.';
    static readonly YourRecruitmentLinkCopied = 'Your recruitment link is copied';
    static readonly InsertTheLinkInstruction =
        'Insert the link in a job ad or email and applicants that click it will automatically be invited to complete the talent assessment. You will be notified by email once an applicant has completed the assessment.';
    static readonly InvitationLink = 'Invitation link';
    static readonly CopyLink = 'Copy link';
    static readonly InsertIn = 'Insert in';
    static readonly JobAds = 'Job ads';
    static readonly JobAdsInsertInstructions =
        'Insert the invitation link directly in a job ad, ensuring all applicants are automatically invited to participate in the talent assessment.';
    static readonly CustomInvitationEmail = 'Custom invitation email';
    static readonly CustomInvitationEmailInstructions =
        'Write your own custom invitation email and insert the link, so you can share it with multiple applicants.';
    static readonly ApplicantsCount = (value: number) => `${value} applicants`;
    static readonly HaveBeenRejected = 'have been rejected.';
    static readonly HasBeenRejected = 'has been rejected.';

    static readonly NonPublicRecruitment = 'Non-public recruitment';
    static readonly TalentAssessment = 'Talent Assessment';
    static readonly CloseTalentAssessment = 'Close talent assessment';
    static readonly PublicRecruitment = 'Public recruitment';
    static readonly ClosedRecruitment = 'Closed recruitment';
    static readonly CloseRecruitment = 'Close recruitment';
    static readonly PreviewShareJob = 'Preview/Share job';
    static readonly EditJob = 'Edit job';

    static readonly Public = 'Public';
    static readonly NonPublic = 'Non-public';

    static readonly PublicCreatePoint1 =
        'Your job ad will automatically be made publicly available on your career page (if enabled/active).';
    static readonly PublicCreatePoint2 = `You have access to all Talentmesh's sourcing options.`;

    static readonly NonPublicCreatePoint1 = 'Your job ad will not be automatically made public, but';
    static readonly NonPublicCreatePoint1Bold = ' you can make it public at a later stage';
    static readonly NonPublicCreatePoint2 = `You have access to all Talentmesh's sourcing options.`;

    static readonly RecruitmentPublished = 'Recruitment published';

    static readonly ErrorRecruitmentPublishingFailed = 'Error: Recruitment publishing failed.';

    static readonly RecruitmentUpdated = 'Recruitment updated';
    static readonly RejectNote =
        'below to inform the selected applicants of the unsuccessful application with the rejection email shown on the right.';
    static readonly RejectNote2 =
        'Alternatively, click "Mark as rejected" to update applicants\' statuses to rejected, without sending email.';
    static readonly ApplicantNameVariable = '{Applicant_name}';
    static readonly RecruitmentHasBeenClosed = (recruitmentName: string) =>
        `The ${recruitmentName} recruitment has been closed`;
    static readonly ApplicantWasRejected = (value: string, plural: boolean) =>
        `and ${value} ${plural ? 'applicants were' : 'applicant was'} rejected.`;
    static readonly One = 'one';
    static readonly YourDontHaveAnyCloseRecruitmentYet =
        "You don't have any closed recruitments yet. Once you close your recruitment it will be shown here.";
    static readonly YouDontHaveActiveRecruitments = "You don't have active recruitments.";

    static readonly NotificationsSettingsHasBeenUpdatedSuccessfully = 'Notification settings updated successfully.';
    static readonly AssessmentNotifications = 'Assessment notifications';
    static readonly EmailMe = 'Email me';
    static readonly WhenApplicantSubmitsAssessment = 'When an applicant submits an assessment ';

    static readonly Found404 = "You've found our 404 page!";
    static readonly PageNotFoundTextBeforeLink =
        "However, we can't seem to find the page you were looking for. Please check if you got the correct URL, or";
    static readonly PageNotFoundTextAfterLink = 'for support if this is happening unexpectedly.';
    static readonly GoToTalentMesh = 'Go to TalentMesh';
    static readonly ContinueEditing = 'Continue editing';
    static readonly ExitWithUnsavedChanges = 'Exit with unsaved changes?';
    static readonly ExitingNowWillDiscardAnyUnsavedChanges =
        'Changes you have made to your email templates need to be saved. Exiting now will discard any unsaved changes.';
    static readonly GetFull = 'Get the full';
    static readonly TalentMeshExperience = 'TalentMesh experience';
    static readonly OnYourDesktopBrowser = 'on your desktop browser';
    static readonly WeWorkHard = "We're working hard to bring you a great mobile experience.";
    static readonly PleaseVisitSite = 'Till then, please visit';
    static readonly PleaseVisitAfterSite = 'on your desktop browser for the full experience.';
    static readonly PersonalityTestResultsFor = 'Personality test results for';
    static readonly AptitudeTestResultsFor = 'Aptitude test results for';
    static readonly ScoreBenchmarks = 'Score benchmarks';
    static readonly CompareTheOverallScore =
        'Compare the overall score or detailed breakdown scores with relevant benchmarks.';
    static readonly BenchmarkOverallScore = 'Overall score';
    static readonly BenchmarkDetailedBreakdown = 'Detailed breakdown';
    static readonly SkillsTestResultsFor = 'Skills test results for';
    static readonly EmotionalIntelligenceTestResultsFor = 'Emotional Intelligence test results for';
    static readonly CVAndApplicantInformation = 'CV & applicant information';
    static readonly ProfileInformation = 'Profile information';
    static readonly Completed = 'Completed';
    static readonly GoToDesktop = 'Go to desktop';

    static readonly PdfDownloadWarning = 'Too many ongoing downloads. Please try again later.';
    static readonly ApplicantsLC = 'applicants';

    static readonly VerifyYourEmailToStartUsingTalentMesh = 'Verify your email to start using TalentMesh';
    static readonly VerifyEmailInstructions1 = 'Click the verification button in the email sent to';
    static readonly VerifyEmailInstructions2 =
        'You should receive the email right away, although it can take up to a few minutes.';
    static readonly HavingIssues = 'Having issues?';

    static readonly IHaveNotReceivedTheEmail = "I haven't received the email.";
    static readonly DontSeeEmailInSpamJunkFolder = "Don't see the email in your inbox or junk/spam folder?";
    static readonly ResendEmail = 'Resend email';
    static readonly IHaveUsedWrongEmailToSignup = 'I used the wrong email to sign up.';

    static readonly CreateANewAccount = 'Create a new account';
    static readonly IfYouMistypedEmailPreviouslyOrTry = 'if you mistyped your email previously, or try to';
    static readonly LogInAgain = 'log in again';
    static readonly WithAnotherEmail = 'with another email.';

    static readonly IHaveVerifiedOnMobile = "I've already verified on my mobile device.";
    static readonly IfIHaveAlreadyVerifiedOnMyMobileDevice =
        'If you have already verified your email successfully on your mobile device,';
    static readonly ClickHereToProceed = 'click here to proceed';
    static readonly ToTalentmesh = 'to TalentMesh.';

    static readonly AnErrorOccurredPleaseTryAgain = 'An error occurred. Please try again.';
    static readonly VerificationEmailSent = 'Verification email sent!';
    static readonly ItSeemsLikeEmailWasNotVerified = "It seems like your email hasn't been verified. Please try again.";
    static readonly PdfDownloadPending = (applicantName: string): string => {
        return `Preparing ${applicantName}'s report for download...`;
    };
    static readonly PdfDownloadSuccess = (applicantName: string): string => {
        return `${applicantName}'s report should start downloading automatically.`;
    };
    static readonly PdfDownloadFailure = (applicantName: string): string => {
        return `${applicantName}'s report failed to download. Please try again.`;
    };

    static readonly MapsCharacteristicsAndSoftSkillsRequiredFor = (jobName: string) =>
        `Maps characteristics & soft skills required for ${jobName}.`;
    static readonly EvaluatesMathematicalLogicalAndVerbalAptitude =
        'Evaluates mathematical, logical and verbal aptitude.';
    static readonly AssessesHardSkillsRequiredFor = (jobName: string) =>
        `Assesses hard skills required for ${jobName}.`;
    static readonly MeasuresTheEmotionalIntelligenceCapabilitiesRequiredForWork =
        'Measures the emotional intelligence capabilities required for work.';

    static readonly QualityTestsBuiltByIndustryExpertsValidatedByPsychologists =
        'Quality tests built by industry experts validated by psychologists';
    static readonly ValidatedByLeadingPsychologists = 'Validated by Leading Psychologists';
    static readonly ExcellentReliabilityScore = 'Excellent Reliability Score';
    static readonly ReliabilityScoreIsMeasured =
        "Reliability score is measured using Cronbach's Alpha, a test publishing industry standard to measure reliability of tests from a scale of 0 to 1.";
    static readonly TalentMeshReliabilityScale = 'TalentMesh reliability scale';
    static readonly PerfectScore = '0.9 and above: Perfect (but likely too long administration time)';
    static readonly ExcellentScore = '0.8 to 0.9: Excellent';
    static readonly GoodScore = '0.7 to 0.8: Good';
    static readonly LowScore = 'Below 0.7: Not recommended for hiring';
    static readonly EstimatedTotalAdministrationTime = 'Estimated total administration time';
    static readonly PleaseSelectAtLeastOneTestToContinue = 'Please select at least one test to continue.';

    static readonly TalentMeshTestsAreCreatedByTheFollowingScientificMethod =
        'TalentMesh tests are created by the following scientific method';
    static readonly SoftAndHardSkillsRequiredForAGivenJobFunction =
        'TalentMesh researches soft and hard skills required for a given job function.';
    static readonly RightIndustryExpertIsIdentifiedAndAssigned =
        'Based on the research, the right industry expert is identified and assigned.';
    static readonly QuestionsBasedOnTheResearchFindings =
        'The industry expert creates the test questions based on the research findings.';
    static readonly ValidatedByALeadingPsychologist =
        'The test questions are handed over to a PhD in psychology for final validation and composition of each test (See "Validated by a Leading Psychologist").';

    static readonly EachTestIsComposedAndValidatedAsFollows = 'Each test is composed and validated as follows';
    static readonly PhDInPsychologyAdministersEachTest =
        "A PhD in psychology administers each test's questions to a big group of relevant participants, in order to identify the psychometric properties.";
    static readonly BasedOnThePsychometricProperties =
        'Based on the psychometric properties, the most relevant questions are selected and retained.';
    static readonly ThePhDInPsychologyThenCreates =
        'The PhD in psychology, then creates the final test composition based on the retained questions and prepares the documentation for test validation.';

    static readonly EnterCouponHere = 'Enter coupon here';
    static readonly Apply = 'Apply';
    static readonly Calculate = 'Calculate';
    static readonly CouponDiscount = 'Coupon discount';
    static readonly CouponApplied = 'Coupon applied';

    static readonly NoRows = 'No rows';

    static readonly ApplicantsProfile = "Applicant's profile";
    static readonly TalentScore = 'Talent Score';
    static readonly ResendInvitation = 'Resend invitation';
    static readonly MarkFavorite = 'Mark as favorite';
    static readonly RemoveFavorite = 'Remove favorite';
    static readonly ResetAssessment = 'Reset assessment';
    static readonly TalentScoreDescription =
        'The Talent Score is an average of individual test scores that the applicant has taken in this assessment.';

    static readonly RelevantQuestionsToAskDuringTheInterviewProcess =
        'Relevant questions to ask during the interview process';
    static readonly YouVeScoredWellFor = "You've scored well for";
    static readonly YouVeNotScoredWellFor = "You've not scored well for";
    static readonly TestResultsShowThatYouIdentifyYourselfAsSomeoneWho =
        'an important characteristic for this role. Test results show that you identify yourself as someone who';
    static readonly DoesThisDescriptionAccuratelyRepresentYou = 'Does this description accurately represent you?';

    static readonly PersonalityScoreOverview = 'Personality score overview';
    static readonly AnOverallScoreIsCalculated = (jobGroup: string): string =>
        `The overall score is calculated based on the scores of personality factors by taking into account their relative importance to this specific job group: ${jobGroup}`;

    static readonly HighTestValidityScore =
        'A high score indicates that the applicant has answered honestly and candidly, and the results can be relied upon. Whereas a low score indicates that the applicant may have engaged in impression management, and you might not be able to rely on the results.';

    static readonly ScoreDetails = 'Score details';
    static readonly PersonalityScoreDetailsHeader =
        'Only personality characteristics researched to have high relative importance (on a scale of one to five) to the job function are included. Scores are shown in percentile.';
    static readonly ScoreOverviewFooter = (name: string, score: number): string =>
        `${name} scored in the ${FormatOrdinal(
            score,
        )} percentile, which is higher than ${score} percent of applicants in the benchmark data for this job group.`;

    static readonly AptitudeScoreOverview = 'Aptitude score overview';
    static readonly TheOverallScoreIsCalculatedBasedOnAptitude =
        'The overall score is calculated based on the scores of two job-relevant aptitude tests.';

    static readonly SkillsScoreOverview = 'Skills score overview';
    static readonly TheOverallScoreIsCalculatedBasedOnSkills = (jobFunction: string): string =>
        `The overall score is calculated based on the scores of on-the-job skills researched to be relevant for this job group: ${jobFunction}.`;

    static readonly Characteristic = 'Characteristic';
    static readonly Score = 'Score';
    static readonly Importance = 'Importance';

    static readonly EmotionalIntelligenceScoreOverview = 'Emotional intelligence score overview';
    static readonly TheOverallScoreIsCalculatedBasedOnEI =
        'The overall score is calculated based on Emotional Intelligence characteristics researched to have the highest relative importance to workplace performance.';
    static readonly EmotionalIntelligenceScoreDetailsHeader =
        'Emotional intelligence characteristics are ranked based on relative importance (on a scale of one to five) to the job group. Scores are shown in percentile.';

    static readonly Created = 'Created';
    static readonly LastViewed = 'Last viewed';
    static readonly HighestTalentScore = 'Highest Talent Score';
    static readonly Assessment = 'Assessment';
    static readonly SkillsTests = 'Skills tests';
    static readonly Ongoing = 'Ongoing';
    static readonly Opened = 'Opened';
    static readonly TestsIncluded = 'Tests included';

    static readonly NoMoreRecruitmentsToShow = 'No more recruitments to show';
    static readonly NoExactMatchesFound = 'No exact matches found';

    static readonly Important = 'Important!';
    static readonly getAssessmentResetDialogWarningList = [
        `An invitation will be sent to the applicant to retake the assessment.`,
        `After you reset, all the current test results will be erased, and the applicant's status will be reset to “Invited.”`,
        `You can only reset a applicant's assessment once.`,
    ];
    static readonly successAssessmentResetToast = (firstName: string): string =>
        `${firstName}'s assessment has been reset successfully.`;

    static readonly errorAssessmentResetToast = (firstName: string): string =>
        `${firstName}'s assessment reset has encountered issues.`;

    static readonly Mandatory = 'Mandatory';
    static readonly Off = 'Off';

    static readonly GoToRecruitmentOverview = 'Go to recruitment overview';

    static readonly PhoneNumber = 'Phone number';
    static readonly ReasonForApplying = 'Reason for applying';
    static readonly ReasonForLeaving = 'Reason for leaving current job';
    static readonly NoticePeriod = 'Notice period';
    static readonly ValidWorkPermitForTheCountry = 'Valid work permit for the country';
    static readonly WhyDoYouWantToWorkForStartup = 'Why do you want to work for a start-up company';
    static readonly RankRemunerationPaceOfLearningWorkLifeBalance =
        'Rank the following in order or importance: remuneration, pace of learning, work life balance';
    static readonly HowImportantAreStructureAndProcesses =
        'How important are structure and processes to you in a work environment';
    static readonly GiveAnExampleOfProject =
        'Please give an example of a project or initiative you started on your own';
    static readonly WhereDoYouSeeYourselfInTheNextFewYears =
        'Where do you see yourself in the next few years, and how does joining our startup help you achieve these goals';
    static readonly ExpectedYearlySalary = 'Expected yearly salary';
    static readonly ExpectedYearlySalaryRange = 'Expected yearly salary range';

    // first onboarding experience
    static readonly WelcomeToTalentMeshGetStartedInJust5Min = 'Welcome to TalentMesh - Get started in just 5 min';
    static readonly WatchOur4MinOnboardingVideo = 'Watch our 4 min onboarding video';
    static readonly CreateAndShareASkillsBasedRecruitmentInJust1Min =
        'Create and share a skills-based recruitment in just 1 min';
    static readonly NeedHelpCreatingYourFirstSkillsBasedRecruitment =
        'Need help creating your first skills-based recruitment?';
    static readonly BookOnboardingMeeting = 'Book onboarding meeting';
    static readonly CreateASkillsBasedRecruitment = 'Create a skills-based recruitment';
    static readonly CreateARecruitmentIn5Min = 'Create a recruitment in 5 min';
    static readonly NeedMoreInformation = 'Need more information, schedule onboarding';
    static readonly Demo = 'demo';

    static readonly Invite = 'Invite';
    static readonly Click = 'Click';

    static readonly XApplicantsHasBeenInvited = (count: number) =>
        `${count} applicant${count > 1 ? 's' : ''} has been invited`;

    static readonly AreYouSureYouWantToLeaveWithoutSendingInvites =
        'Are you sure you want to leave without sending invites to applicants?';
    static readonly AreYouSure = 'Are you sure?';
    static readonly Stay = 'Stay';
    static readonly LeaveThePage = 'Leave the page';
    static readonly CurriculumVitae = 'Curriculum vitae';
    static readonly CV = 'CV';
    static readonly Pdf = 'Pdf';
    static readonly DocDocx = 'Doc/Docx';
    static readonly DownloadCv = 'Download CV';
    static readonly DownloadFailed = 'Download Failed';
    static readonly SorryWeWereUnableToDownloadCv =
        "Sorry, we were unable to download applicant's CV. Please try again later.";
    static readonly DownloadSuccessful = 'Download Successful';
    static readonly CvHasBeenDownloadedSuccessfully = "Applicant's CV has been downloaded successfully.";

    static readonly PreviewRecruitmentInvitationEmail = 'Preview - Invitation Email';
    static readonly RecruitersNamePlaceholder = "{RECRUITER'S NAME}";
    static readonly CompanysNamePlaceholder = "{COMPANY'S NAME}";
    static readonly AssessmentUrlPlaceholder = "{ASSESSMENT'S URL}";
    static readonly Hi = 'Hi';
    static readonly From = 'from';
    static readonly HasInvitedYouToTakeATalentMeshAssessment = 'has invited you to take a TalentMesh assessment';
    static readonly AsPartOfTheirRecruitmentProcess = 'as part of their recruitment process.';
    static readonly ThePurposeOfThisAssessmentIsToGenerate =
        'The purpose of the assessment is to generate a holistic and objective evaluation of you as a job applicant.';
    static readonly ItsAnOpportunityToShowcaseYourUnique =
        "It's an opportunity to showcase your unique personality, aptitude, and skills";
    static readonly WhichCanBeHardToEvaluateSolelyFromReadingYourCV =
        ', which can be hard to evaluate solely from reading your CV and interviews.';
    static readonly StartYourAssessmentNowOrContinueWhereYouLeftOff =
        'Start your assessment now, or continue where you left off';
    static readonly GoToAssessment = 'Go to assessment';
    static readonly OrCopyAndPasteThisUrlIntoYourBrowser = 'Or copy and paste this URL into your browser:';
    static readonly AllTheBest = 'All the best,';
    static readonly TheTalentMeshTeam = 'The TalentMesh team';
    static readonly Preview = 'Preview';

    static readonly SearchByNameOrEmail = 'Search by name or email';
    static readonly InviteNewMembers = 'Invite New Members';
    static readonly InviteMembers = 'Invite members';
    static readonly CreateRecruitments = 'Create recruitments';
    static readonly UseATSFunctionality = 'Use ATS functionality';
    static readonly ViewApplicants = 'View applicants';

    static readonly NameAndEmail = 'Name and Email';
    static readonly Roles = 'Roles';

    static readonly ManageInvitation = 'Manage invitation';
    static readonly ManageMember = 'Manage member';
    static readonly RevokeInvitation = 'Revoke invitation';
    static readonly ManageAccessRights = 'Manage access rights';
    static readonly RemoveAccess = 'Remove access';
    static readonly Member = 'Member';
    static readonly Expired = 'Expired';
    static readonly Role = 'Role';

    static readonly InviteNewTeamMember = 'Invite new team member';

    static readonly Admin = 'Admin';
    static readonly ThisUserWillHaveFullAccessToTheAccount =
        'This user will have full access to the account. This means the user can access all features, including inviting & managing team members, creating recruitments, and using ATS functionality';

    static readonly SendInvitation = 'Send invitation';
    static readonly TheInvitationHasBeenSentTo = (email: string) => `The invitation has been sent to ${email}`;
    static readonly TheInvitationHasBeenResentTo = (email: string) => `The invitation has been resent to ${email}`;
    static readonly SuccessTheRoleOfTheTeamMemberHasBeenUpdatedSuccessfully =
        'Success! The role of the team member has been updated successfully.';

    static readonly RemoveUser = 'Remove User';
    static readonly RemoveMemberAccess = 'Remove member access';

    static readonly AreYouSureYouWantToRemoveTheAccessForMemberName = `Are you sure you want to remove the access for`;
    static readonly AreYouSureYouWantToRevokeInvitationFor = `Are you sure you want to revoke invitation for`;
    // Company profile tab
    static readonly CompanyProfile = 'Company profile';
    static readonly CompanyNameAsterisk = 'Company name*';
    static readonly SubdomainAsterisk = 'Subdomain*';
    static readonly YouCanUseLettersNumberAndDashes =
        "You can use letters, numbers, and dashes. It just can't end with a dash";
    static readonly TheCompanyNameThatIsDisplayedToApplicants = 'The company name that is displayed to applicants.';
    static readonly BrandElements = 'Brand elements';
    static readonly UploadYourLogoAndIconHereToShowcaseYourCompany = `Upload your logo and icon here to showcase your company's visual identity.`;
    static readonly Icon = 'Icon';
    static readonly AScaledDownVersionOfYourLogo = 'A scaled-down version of your logo shown to applicants.';
    static readonly CompanyLogo = 'Company logo';
    static readonly TheFullScaleVersionOfYourLogo = 'The full-scale version of your logo shown to applicants.';
    static readonly SuccessfulImageChangeToaster = 'Updated successfully';
    static readonly FailedImageUploadToaster = 'There was a problem uploading your image. Please try again.';
    static readonly FailedImageResetToaster = 'There was a problem removing your image. Please try again.';
    static readonly CompanyNameInputLengthValidation = 'Company name should not exceed 128 characters.';
    static readonly CompanySlugInputLengthValidation = 'Subdomain should not exceed 128 characters.';
    static readonly CompanyDescriptionInputLengthValidation = 'Company description should not exceed 4000 characters.';
    static readonly CompanySlugInvalidFormat =
        "Subdomain format is invalid. Please use lowercase letters, numbers, and dashes. It just can't end with a dash";
    static readonly CompanyBrandElementsValidationDescription =
        'You can upload .jpg, .jpeg, or .png files with a file size up to 1 MB. For the best results, use .png files with a transparent background.';

    // Default ImageCrop component strings
    static readonly UploadImage = 'Upload image';
    static readonly RemoveImage = 'Remove image';
    static readonly Upload = 'Upload';
    static readonly CropYourImage = 'Crop your image';
    static readonly SelectTheAreaToBeCropped = 'Select the area to be cropped';
    static readonly DefaultImageCropDialogProcessingErrorText =
        'We could not process the selected image, please select a different image.';
    static readonly DefaultImageTypeValidationErrorText = `Looks like the file type isn't supported. Please upload a valid file format.`;
    static readonly DefaultImageSizeValidationErrorText = 'Your file should be no more than 1MB in size.';

    // Career page tab
    static readonly CareerPage = 'Career page';
    static readonly CareerPageTabDescription = `View and edit your career page to benefit from employer branding and attract more applicants`;
    static readonly PublishedDate = 'Published date:';
    static readonly ActiveJobs = 'Active jobs:';
    static readonly EditCareerPage = 'Edit career page';
    static readonly CreateCareerPage = 'Create career page';
    static readonly ViewCareerPage = 'View career page';

    static readonly CurrentLocation = 'Current location';
    static readonly WillingToRelocate = 'Willing to relocate';
    static readonly Yes = 'Yes';
    static readonly No = 'No';
    static readonly HighestLevelOfEducation = 'Highest level of education';
    static readonly JobExperience = 'Job Experience';
    static readonly Education = 'Education';
    static readonly RecruitmentInformation = 'Recruitment information';

    static readonly EightGrade = '8th grade or lower';
    static readonly HighSchool = 'High school diploma or equivalent';
    static readonly AssociateDegree = "Associate's degree";
    static readonly BachelorDegree = "Bachelor's degree";
    static readonly MasterDegree = "Master's Degree";
    static readonly MBA = 'MBA';
    static readonly PostgraduateDegree = 'Postgraduate degree';
    static readonly PHDDegree = 'PHD or doctorate degree';

    static readonly NoRelevantExperience = 'No relevant experience';
    static readonly LessThanOneYear = 'Less than 1 year';
    static readonly OneToThreeYears = '1 - 3 years';
    static readonly FourToSixYears = '4 - 6 years';
    static readonly SevenToNineYears = '7 - 9 years';
    static readonly TenToTwelveYears = '10 - 12 years';
    static readonly MoreThan12Years = 'More than 12 years';

    static readonly ImCurrentlyUnemployed = "I'm currently unemployed";
    static readonly InvitationLinkOrEmail = 'Invitation link or email';

    static readonly RejectByCount = (count: number): string => `${UIStrings.Reject} (${count})`;
    static readonly At = 'at';
    static readonly Present = 'Present';
    static readonly JobType = 'Job type';
    static readonly FieldOfEducationIn = 'Field of education in';
    static readonly LatestJobExperience = 'Latest job experience';

    static readonly RewriteInMoreFriendlyTone = 'Rewrite in more friendly tone';
    static readonly RewriteInMoreFormalTone = 'Rewrite in more formal tone';
    static readonly ImproveWithAI = 'Improve with AI';

    static readonly CompanyDescription = 'Company description';
    static readonly CompanyDescriptionExplanation =
        'The company description helps to set you apart from other employers and create a strong employer brand. It is included in all your job posts.';

    static readonly JobAdGenerationErrorTitle = 'Job ad could not be generated by AI';
    static readonly JobAdGenerationErrorMessage =
        'We were unable to generate your job ad, as we could not establish a connection to OpenAI.';
    static readonly PleaseGenerateAgainLater =
        'Please try again later, or manually write the job ad title, job description, and company description.';

    static readonly JobAdImprovementErrorTitle = 'Job ad could not be improved by AI';
    static readonly JobAdImprovementErrorMessage =
        'We were unable to improve your job ad, as we could not establish a connection to ChatGPT.';
    static readonly PleaseImproveAgainLater = 'Please try again later or manually improve the job ad.';
    static readonly IUnderstand = 'I understand';
    static readonly Title = 'Title';
    static readonly CompanyName = 'Company name';
    static readonly FieldOfEducation = 'Field of education';
    static readonly NameOfSchool = 'Name of school';

    static readonly Other = 'Other';
    static readonly Filter = 'Filter';
    static readonly ClearFilters = 'Clear filters';
    static readonly TheInvitationHasBeenSendTo = (email: string) => `The invitation has been sent to ${email}`;

    static readonly NoTeamMembersFound = 'No team members found';

    static readonly LinkNotFound = 'Link not found';
    static readonly LinkNotFoundMessage =
        "The link you're trying to access doesn't seem to work. Please double-check the URL or reach out to the person who sent it to you.";
    static readonly LinkExpired = 'Link expired';
    static readonly LinkExpiredMessage =
        'The link you have attempted to access is no longer valid. Please contact the person that sent you the link and request a new link.';
    static readonly LinkAlreadyRedeemed = 'Link already redeemed';
    static readonly LinkAlreadyRedeemedMessage =
        'The link you have attempted to use has already been redeemed. If you are trying to access your account or require further assistance, please proceed to log in.';

    static readonly LogIn = 'Log in';

    static readonly YourAccountHasBeenRevoked = 'Your account has been revoked by the administrator';
    static readonly YouCanNoLongerJoin = 'You can no longer join';
    static readonly InvitationMistakeMessage =
        'If this is a mistake, contact your administrator and ask them to send you a new invitation.';
    static readonly ToTryADifferentAccountPleaseLogout =
        'To try a different account or re-attempt joining, please log out.';
    static readonly InvitationHasBeenResend = (email: string) => `The invitation has been resent to ${email}`;

    static readonly TheChangesHaveBeenSavedSuccessfully = 'The changes have been saved successfully.';
    static readonly CareerPageHaveBeenPublishedSuccessfully = 'Career page have been published successfully.';
    static readonly CareerErrorSaveOrPublish = 'Could not save or publish any changes.';
    static readonly ChangesYouHaveMadeToYourCareerPage =
        'Changes you have made to your career page need to be saved. Exiting now will discard any unsaved changes.';
    static readonly Confirm = 'Confirm';

    static readonly AnnualFixedAmount = 'Annual fixed amount';
    static readonly AnnualFixedTooltipText = 'Input the total amount of annual salary without bonus.';
    static readonly AnnualTargetBonus = 'Annual target bonus';
    static readonly AnnualTargetBonusTooltipText = 'Input the total target bonus for a year.';

    static readonly CompensationDetails = 'Compensation details';
    static readonly SecurePayment = 'Secure payment';
    static readonly Invoice = 'Invoice';
    static readonly Description = 'Description';
    static readonly OneHire = (amount: string, feePercentage: number): string =>
        `1 hire total salary of ${amount} * ${feePercentage}% fee`;

    static readonly SavedCard = 'Saved card';
    static readonly NewCard = 'New card';
    static readonly JobCategory = 'Job category';
    static readonly JobFunction = 'Job function';

    static readonly PostOnJobBoards = 'Post on job boards';
    static readonly RegionFocus = 'Region focus';
    static readonly Global = 'Global';
    static readonly USCanadaAndUK = 'US, Canada and UK';
    static readonly AllJobTypes = 'All job types';
    static readonly AllOtherJobBoards = 'All other job boards';
    static readonly ConnectToAnyJobBoard = 'Connect to any job board';
    static readonly getConnectToJobBoards = (jobBoardName: string) => `Connect to ${jobBoardName}’s job board`;
    static readonly SelectYourLinkedInProfileType = 'Select your LinkedIn profile type';
    static readonly NormalProfile = 'Normal Profile';
    static readonly RecruiterProfile = 'Recruiter Profile';
    static readonly getSeamlessAttract = (jobBoardName: string) =>
        `Seamless attract more applicants by connecting your TalentMesh job ad to ${jobBoardName}’s job board.`;
    static readonly WatchOurTwoMinuteStep =
        'Watch our 2 minute step-by-step instruction video and your are ready to set up the connection.';
    static readonly getOnceTheConnection = (jobBoardName: string) =>
        `Once the connection is set up, all applicants from ${jobBoardName} are automatically routed to TalentMesh.`;
    static readonly VideoGuideTwoMinutes = 'Video guide (2 min)';
    static readonly AllJobBoardsShouldAllow = `All job boards should allow you to route applicants to TalentMesh, so you can benefit from both the job board’s sourcing capabilities and all the skills-based hiring features on TalentMesh. Please follow the guide below to connect your job ad to any job board.`;
    static readonly CreateARecruitmentOnTalentMeshTitle = 'Create a recruitment on TalentMesh and open the job ad';
    static readonly CreateARecruitmentOnTalentMeshDescription = `Create a recruitment on TalentMesh, which will include a job ad that is optimized for your unique hiring situation. Under 'My Recruitments', find the correct recruitment and then open the job ad by selecting 'Preview Job/Share' from the drop-down menu.`;
    static readonly CreateJobBoardPostAndCopyInTitle = 'Create job board post and copy in information from TalentMesh';
    static readonly CreateJobBoardPostAndCopyInDescription =
        'In a new browser window, open your preferred job board. Follow the instructions on how to create a job post. Copy in the TalentMesh job details when you get to the job ad section.';
    static readonly RouteApplicantToTalentMeshTitle = 'Route applicant to TalentMesh';
    static readonly RouteApplicantToTalentMeshDescription =
        'When you get to the section on where to route candidates, choose the option that allows you to direct applications to TalentMesh, and ';
    static readonly CopyTheLinkShownBelow = 'copy the link shown below.';
    static readonly JobAdLinkForJobBoards = 'Job ad link for job boards';
    static readonly IfYouAreHavingIssuesConnecting =
        'If you are having issues connecting your job board to TalentMesh, please';
    static readonly BookAMeeting = 'book a meeting';
    static readonly WithUsAndWeWillHelpYouConnectIt = 'with us and we will help you connect it.';

    static readonly LinkedIn = 'LinkedIn';
    static readonly Indeed = 'Indeed';
    static readonly ZipRecruiter = 'ZipRecruiter';
    static readonly Monster = 'Monster';

    static readonly EnableCareerPage = 'Enable career page';
    static readonly BenefitFromEmployerBranding =
        'Benefit from employer branding on your career page and attract more applicants. You can view and edit your career page under';
    static readonly YourCareerPageIsActive = 'Your career page is active';
    static readonly CareerPageNotEnabled = 'Your career page is not enabled';
    static readonly JobStatusOnCareerPage = 'Job status on career page';
    static readonly JobNotPostedBecauseCareerPageNotEnabled =
        'Job is not posted because your career page is not enabled';
    static readonly IntegrateCareerPageWithWebsite = 'Integrate your career page with your website';
    static readonly ConnectCareerPageToWebsite =
        "You can connect your career page directly to your company's website. Provide the link below to your webpage administrator and have them embed it on the website. Please note that every public job you post will automatically appear on your career page.";
    static readonly JobNotPostedBecauseRecruitmentNotPublic =
        'Job not posted because your recruitment is not public. Change status under';
    static readonly JobSuccessfullyPostedToCareerPage = 'Job successfully posted to your career page';
    static readonly SetUpYourCareerPage = 'Set up your career page';
    static readonly SetUpAndEnableCareerPage =
        'Set up and enable your career page to attract more applicants. Our career page builder is easy to use, and takes 10-15 minutes. Need a hand? Schedule a meeting with our team for help with setting up your career page.';
    static readonly BookSetupMeeting = 'Book setup meeting';
    static readonly SetUpCareerPage = 'Set up career page';
    static readonly NonPublicRecruitmentCreated = 'Your non-public recruitment is created';
    static readonly NonPublicRecruitmentCreatedText = `Well done! You can now use TalentMesh's sourcing features to attract applicants.`;

    static readonly PublicRecruitmentCreated = 'Your public recruitment is created';
    static readonly PublicRecruitmentCreatedText1 =
        'Well done, your job is now publicly available on your career page! ';
    static readonly PublicRecruitmentCreatedText2 = `You can now use TalentMesh's other sourcing features to attract more applicants.`;

    static readonly PublicRecruitmentCreatedNoCareerPage = 'Your recruitment is created';
    static readonly PublicRecruitmentCreatedNoCareerPageText1 = `Well done! You can now use TalentMesh's sourcing features to attract applicants. `;
    static readonly PublicRecruitmentCreatedNoCareerPageText2 =
        'Please note, your job ad has not been made public yet because you need to enable your career page';
    static readonly PublicRecruitmentCreatedNoCareerPageText3 = ' (under attract applicants page).';

    static readonly CareerPageHasBeenPublished =
        'Your career page has been published! Remember to link it to your company website.';
    static readonly StayOnCareerPageEditor = 'Stay on career page editor';
    static readonly GoToAttractApplicants = 'Go to attract applicants';
    static readonly GoToSettings = 'Go to settings';

    static readonly ConnectCareerPageToWebsiteInstruction =
        "You can now connect your career page directly to your company's website by following the guide on the career pages tab under attract applicants.";

    static readonly PreviewQuestions = 'Preview questions';
    static readonly TalentMeshTestPreviewQuestions = (test: TestTypes) =>
        // turns pascal case into normal sentence, (EmotionalIntelligence -> Emotional Intelligence)
        `TalentMesh ${test.replace(/([a-z])([A-Z])/g, `$1 $2`)} test preview questions`;
    static readonly Question = 'Question';
    static readonly Answer = 'Answer';
    static readonly ChooseAnswers = 'Choose one of the following answers below:';
    static readonly EnterAnswer = 'Enter your answer below:';
    static readonly ChooseWhichBestDescribeYou = 'Choose which best describe you';

    static readonly TestInformation = 'Test information';
    static readonly TestsCompleted = 'Tests completed';
    static readonly TestLanguage = 'Test language';
    static readonly TestsLanguage = 'Tests language';
    static readonly ApplicantsSelectLanguage = 'Applicants select language';
    static readonly English = 'English';
    static readonly German = 'German';
    static readonly Italian = 'Italian';
    static readonly French = 'French';
    static readonly Spanish = 'Spanish';
    static readonly Portuguese = 'Portuguese';

    static readonly Experience = 'Experience';
    static readonly ExperienceScore = 'Experience Score';
    static readonly TheExperienceScoreShowsHowWellTheCandidateSResumeMatchesTheJobRequirements =
        'The Experience Score shows how well the candidate’s resume matches the job requirements. It’s a quick way to see if their experience fits the role.';
    static readonly Justification = 'Justification';
    static readonly NewJustificationAndScore = 'New justification and score';
    static readonly NewScore = 'New score';
    static readonly NewJustification = 'New justification';

    static readonly AdjustedByRecruiter = 'Adjusted by recruiter';
    static readonly PleaseUpdateScoreAndJustificationToProceed = 'Please update score and justification to proceed.';
}

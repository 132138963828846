import React, { useEffect, useState } from 'react';
import { Box, Label, Stack } from '@talentmesh/core';
import { useTheme } from '@mui/material';
import { $generateHtmlFromNodes, RichTextEditor } from '@talentmesh/rte';
import { useField, useFormikContext } from 'formik';
import UIStrings from '../../../../../../Utils/UIStrings';
import JobDescriptionCharCounter from './JobDescriptionCharCounter';
import { JobAdFieldTypes, JobDescriptionCharactersLimit } from '../JobAdStepUtils';
import { useNotificationContext } from '../../../../../../Context/NotificationContext';
import { useModifyRecruitmentContext } from '../../../Contexts/ModifyRecruitmentContext';
import useModifyRecruitmentContextHook from '../../../Contexts/useModifyRecruitmentContextHook';
import ProcessingState from '../../../../../../Models/ProcessingState';
import RTELoadingOverlay from './RTELoadingOverlay';
import DescriptionWings from './DescriptionWings.svg';
import useDebounce from '../../../../../../Hooks/UseDebounce';

interface JobDescriptionProps {
    initialHtml: string;
}

function JobDescription({ initialHtml }: JobDescriptionProps): JSX.Element {
    const action = useModifyRecruitmentContext();
    const { processingState } = useModifyRecruitmentContextHook(action);
    const { showFailToaster } = useNotificationContext();
    const [startUp, setStartUp] = useState(true);
    const [, , helpers] = useField(JobAdFieldTypes.Description);
    const [fieldLen, metaLen, helpersLen] = useField(JobAdFieldTypes.DescriptionLen);
    const { isSubmitting } = useFormikContext();
    const theme = useTheme();
    const [jobDescription, setJobDescription] = useState({
        value: '',
        textLength: 0,
        initialized: false,
    });
    const debouncedJobDescription = useDebounce(jobDescription, 50);
    const loading = processingState === ProcessingState.Processing;

    // actually propagate job description to formik here
    // for performance purposes
    useEffect(() => {
        if (!jobDescription.initialized) {
            setJobDescription((p) => ({ ...p, initialized: true }));
            return;
        }

        helpers.setValue(debouncedJobDescription.value);
        helpersLen.setValue(debouncedJobDescription.textLength);
        if (!metaLen.touched && !startUp) {
            helpersLen.setTouched(true);
        } else {
            setStartUp(false);
        }
    }, [debouncedJobDescription]);

    return (
        <Stack spacing={theme.spacing(1.25)}>
            <Label>{UIStrings.JobDescription}</Label>
            <Box
                sx={{
                    borderRadius: theme.spacing(1.25),
                    border: `1px solid ${theme.palette.border.main}`,
                    borderWidth: '1px',
                    backgroundColor: theme.palette.grey[100],
                }}
                display="flex"
                flexDirection="column"
                paddingLeft={theme.spacing(2.5)}
                paddingTop={theme.spacing(2.5)}
                paddingRight={theme.spacing(2.5)}
                paddingBottom={theme.spacing(2.5)}
                height={theme.spacing(86)}
                data-cy="JobDescriptionRTE"
            >
                <RichTextEditor
                    editable={!isSubmitting}
                    loading={loading}
                    loadingOverlay={
                        <RTELoadingOverlay dataCy="JobAdDescriptionLoadingOverlay" svg={DescriptionWings} />
                    }
                    data-cy={JobAdFieldTypes.Description}
                    onError={() => {
                        showFailToaster();
                    }}
                    initialHtml={initialHtml}
                    features={['headingStyle', 'bold', 'italic', 'ol', 'ul']}
                    onChange={(editorState, editor) => {
                        editorState.read(() => {
                            const textLength = editor.getRootElement()?.textContent?.length || 0;
                            const value = $generateHtmlFromNodes(editor);

                            setJobDescription((p) => ({
                                ...p,
                                value,
                                textLength,
                            }));
                        });
                    }}
                    contentEditableProps={{
                        sx: {
                            overflowY: 'auto',
                            flex: 1,
                            color: isSubmitting ? theme.palette.text.disabled : undefined,
                        },
                    }}
                    toolbarProps={{ sx: { marginBottom: theme.spacing(1.25) } }}
                />
            </Box>
            <JobDescriptionCharCounter
                charNum={fieldLen.value}
                charLimit={JobDescriptionCharactersLimit}
                hasError={!!metaLen.error && metaLen.touched}
                error={metaLen.error}
            />
        </Stack>
    );
}

export default JobDescription;

import React from 'react';
import { IconButton, Stack, Typography } from '@talentmesh/core';
import { ChevronLeftIcon } from '@talentmesh/icons';
import { useTheme } from '@mui/material/styles';
import { useCandidatesOverviewContext } from '../../Contexts/CandidatesOverviewContext';
import UIStrings from '../../../../../Utils/UIStrings';
import { VerticalDivider } from '../../../Results/Components/Tabs/Components/Common/CustomDivider';
import RoutePath from '../../../../../Routing/RoutePath';
import useTalentMeshHistory from '../../../../../Routing/useTalentMeshHistory';

function CandidatesOverviewHeaderContent(): JSX.Element {
    const theme = useTheme();
    const { assessmentName, jobCategoryName, jobFunctionName } = useCandidatesOverviewContext();
    const history = useTalentMeshHistory();

    const backClickHandler = () => {
        history.push(RoutePath.CompanyRoot);
    };

    return (
        <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
            <Stack direction="row" alignItems="center" spacing={1}>
                <IconButton onClick={backClickHandler}>
                    <ChevronLeftIcon />
                </IconButton>
                <Typography color="secondary" variant="h4">
                    {UIStrings.ApplicantsOverview}
                </Typography>
            </Stack>
            <VerticalDivider />
            <Stack>
                <Typography
                    color="secondary"
                    variant="h4"
                    sx={{ overflowWrap: 'anywhere', maxWidth: theme.spacing(38) }}
                >
                    {assessmentName}
                </Typography>
                {jobCategoryName && jobFunctionName && (
                    <Typography
                        color="secondary"
                        variant="caption"
                        sx={{ overflowWrap: 'anywhere', maxWidth: theme.spacing(50) }}
                    >
                        {jobCategoryName}: {jobFunctionName}
                    </Typography>
                )}
            </Stack>
        </Stack>
    );
}

export default CandidatesOverviewHeaderContent;

import React from 'react';
import { Link } from 'react-router-dom';
import { Box, SpanTypography, Typography } from '@talentmesh/core';
import { useCareerPageTabContext } from '../Contexts/CareerPageTabContext';
import UIStrings from '../../../../Utils/UIStrings';
import RoutePath from '../../../../Routing/RoutePath';
import useCompanyIdRoute from '../../../../Routing/useCompanyIdRoute';

function JobStatusTypography(): JSX.Element {
    const { isCareerPageEnabled, isRecruitmentPublic } = useCareerPageTabContext();
    const linkUrl = useCompanyIdRoute(RoutePath.CompanyRoot);

    if (!isCareerPageEnabled) {
        return <Typography variant="body2">{UIStrings.JobNotPostedBecauseCareerPageNotEnabled}</Typography>;
    }

    if (isCareerPageEnabled && !isRecruitmentPublic) {
        return (
            <Box>
                <SpanTypography variant="body1">{`${UIStrings.JobNotPostedBecauseRecruitmentNotPublic} `}</SpanTypography>
                <Link to={linkUrl}>{UIStrings.MyRecruitments.toLowerCase()}</Link>
            </Box>
        );
    }

    return <Typography variant="body2">{UIStrings.JobSuccessfullyPostedToCareerPage}</Typography>;
}

export default JobStatusTypography;

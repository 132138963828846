import { generatePath } from 'react-router';
import useCompanyId from '../Hooks/UseCompanyId';

function useCompanyIdRoute(route: string) {
    const companyId = useCompanyId();
    const url = generatePath(route, { companyId });
    return url;
}

export default useCompanyIdRoute;

import { DirectReportsType, EmploymentTypes, WorkArrangements, WorkExperienceType } from '@talentmesh/core';
import { defaultLanguage } from '../../Utils/JobAdLanguage/JobAdLanguages';
import { DefaultLocationDetailsDTO, LocationDetailsDTO } from './LocationDetailsDTO';

export interface JobDetailsDTO {
    name: string;
    workArrangement: WorkArrangements;
    locationDetails: LocationDetailsDTO;
    jobFunctionId: number;
    workExperience: WorkExperienceType;
    employmentType: EmploymentTypes;
    directReports: DirectReportsType;
    yearlySalaryFrom: number | null;
    yearlySalaryTo: number | null;
    currency: string | null;
    jobDescriptionLanguage: string;
}

export const DefaultJobDetailsDTO: JobDetailsDTO = {
    directReports: 'None',
    workArrangement: 'OnLocation',
    jobFunctionId: 0,
    name: '',
    workExperience: 'EntryLevel',
    employmentType: 'FullTime',
    locationDetails: DefaultLocationDetailsDTO,
    yearlySalaryFrom: null,
    yearlySalaryTo: null,
    currency: null,
    jobDescriptionLanguage: defaultLanguage.label,
};

export interface JobAdDetailsDTO extends JobDetailsDTO {
    jobFunctionName: string;
    jobCategoryName: string;
}

export const DefaultJobAdDetailsDTO: JobAdDetailsDTO = {
    ...DefaultJobDetailsDTO,
    jobFunctionName: '',
    jobCategoryName: '',
};

import React, { SyntheticEvent, useState } from 'react';
import { Box, MenuButton, MenuItemWithValueProps } from '@talentmesh/core';
import { useTheme } from '@mui/material';
import { RTEInitValue } from '@talentmesh/rte';
import { useAssessmentClient, useJobAdClient } from '../../../../Hooks/ClientHooks';
import { AssessmentStatuses } from '../../../../Models/AssessmentData';
import RoutePath from '../../../../Routing/RoutePath';
import ConfirmDialog from '../../../Dialogs/Confirm/ConfirmDialog';
import UIStrings from '../../../../Utils/UIStrings';
import { useNotificationContext } from '../../../../Context/NotificationContext';
import { jobsBaseUrl } from '../../../../AppSettings';
import QueryParams from '../../../../Routing/QueryParams';
import { useAssessmentsOverviewContext } from '../Contexts/AssessmentsOverviewContext';
import useTalentMeshHistory from '../../../../Routing/useTalentMeshHistory';
import { useCompanyContext } from '../../../../Context/CompanyContext';
import { AssessmentOverview } from '../../../../Models/AssessmentOverview';
import { isDefaultLanguageByShortCode } from '../../../../Utils/JobAdLanguage/JobAdLanguages';

function getLabel(assessmentStatus: AssessmentStatuses, jobAdId?: string): string {
    let label = UIStrings.TalentAssessment;

    if (jobAdId) {
        switch (assessmentStatus) {
            case AssessmentStatuses.NonPublic:
                label = UIStrings.NonPublicRecruitment;
                break;
            case AssessmentStatuses.Public:
                label = UIStrings.PublicRecruitment;
                break;
            case AssessmentStatuses.Closed:
                label = UIStrings.ClosedRecruitment;
                break;
            default:
                throw new Error('Invalid assessment status');
        }
    }

    return label;
}

function buildMenuOptions(
    assessmentStatus: AssessmentStatuses,
    setOpenConfirmDialog: React.Dispatch<React.SetStateAction<boolean>>,
    companySlug: string,
    history: ReturnType<typeof useTalentMeshHistory>,
    publishRecruitmentAsync: () => Promise<void>,
    updateCompanyDescriptionAsync: () => Promise<void>,
    jobAdId?: string,
): MenuItemWithValueProps[] {
    const options = [];

    // talent assessment
    if (!jobAdId) {
        options.push({
            menuItemLabel: UIStrings.CloseTalentAssessment,
            dense: true,
            onClick: (e: SyntheticEvent) => {
                e.stopPropagation();
                e.preventDefault();
                setOpenConfirmDialog(true);
            },
        });
        return options;
    }

    // In NonPublic status, we can publish the recruitment
    if (assessmentStatus === AssessmentStatuses.NonPublic) {
        options.push({
            menuItemLabel: UIStrings.PublishRecruitment,
            dense: true,
            onClick: async (e: SyntheticEvent) => {
                e.stopPropagation();
                e.preventDefault();
                await publishRecruitmentAsync();
                await updateCompanyDescriptionAsync();
            },
        });
    }

    // Below are common options for both NonPublic and Public status
    options.push({
        menuItemLabel: UIStrings.PreviewShareJob,
        dense: true,
        onClick: (e: SyntheticEvent) => {
            e.stopPropagation();
            e.preventDefault();
            const url = `${jobsBaseUrl}/${companySlug}/jobs/${jobAdId}`;
            window.open(url, '_blank');
        },
    });

    options.push({
        menuItemLabel: UIStrings.EditJob,
        dense: true,
        onClick: (e: SyntheticEvent) => {
            e.stopPropagation();
            e.preventDefault();
            history.push(`${RoutePath.EditRecruitment}?${QueryParams.JobAdId}=${jobAdId}`);
        },
    });

    options.push({
        menuItemLabel: UIStrings.CloseRecruitment,
        dense: true,
        onClick: (e: SyntheticEvent) => {
            e.stopPropagation();
            e.preventDefault();
            setOpenConfirmDialog(true);
        },
    });

    return options;
}

interface RecruitmentMenuButtonProps
    extends Pick<AssessmentOverview, 'id' | 'jobAdId' | 'name' | 'status' | 'jobDescriptionLanguage'> {}

function RecruitmentMenuButton({
    id: assessmentId,
    jobAdId,
    name: assessmentName,
    status: assessmentStatus,
    jobDescriptionLanguage,
}: RecruitmentMenuButtonProps): JSX.Element {
    const history = useTalentMeshHistory();
    const theme = useTheme();
    const jobAdClient = useJobAdClient();
    const [isActionInProgress, setIsActionInProgress] = useState(false);

    const { currentCompanyProfile, updateCompanyProfile } = useCompanyContext();

    const { loadPageAsync, assessments } = useAssessmentsOverviewContext();

    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const { showSuccessToaster, showFailToaster } = useNotificationContext();

    const assessmentClient = useAssessmentClient();

    const closeConfirmDialog = (event: React.SyntheticEvent) => {
        event.stopPropagation();
        event.preventDefault();
        setOpenConfirmDialog(false);
    };

    const publishRecruitmentAsync = async () => {
        try {
            setIsActionInProgress(true);
            await assessmentClient.publishRecruitmentAsync(assessmentId);
            await loadPageAsync(0, assessments.length);
            showSuccessToaster(UIStrings.RecruitmentPublished);
        } catch {
            showFailToaster(`${UIStrings.ErrorRecruitmentPublishingFailed} ${UIStrings.PleaseTryAgain}`);
        } finally {
            setIsActionInProgress(false);
        }
    };

    const updateCompanyDescriptionAsync = async () => {
        // if it is the first time a public assessment is published and company descritpion is empty
        // we propagate company description from job ad to company profile.
        if (
            currentCompanyProfile.companyDescription === RTEInitValue &&
            isDefaultLanguageByShortCode(jobDescriptionLanguage)
        ) {
            const jobAd = await jobAdClient.getJobAdAsync(jobAdId ?? '');
            await updateCompanyProfile({
                ...currentCompanyProfile,
                companyDescription: jobAd.companyDescription,
            });
        }
    };

    const closeAssessmentAsync = async () => {
        await assessmentClient.closeAssessmentAsync(assessmentId, false, false);
    };

    const label = getLabel(assessmentStatus, jobAdId);

    const recruitmentMoreMenuOptions: MenuItemWithValueProps[] = buildMenuOptions(
        assessmentStatus,
        setOpenConfirmDialog,
        currentCompanyProfile.companySlug,
        history,
        publishRecruitmentAsync,
        updateCompanyDescriptionAsync,
        jobAdId,
    );

    return (
        <>
            <Box width={theme.spacing(28)}>
                <MenuButton
                    label={label}
                    buttonProps={{
                        disabled: assessmentStatus === AssessmentStatuses.Closed || isActionInProgress,
                        variant: 'outlined',
                        size: 'medium',
                        color: 'primary',
                        fullWidth: true,
                    }}
                    menuItems={recruitmentMoreMenuOptions}
                    data-cy="menuButton"
                />
            </Box>
            <ConfirmDialog
                open={openConfirmDialog}
                onClose={closeConfirmDialog}
                onCancel={closeConfirmDialog}
                onOk={async (event) => {
                    try {
                        closeConfirmDialog(event);
                        await closeAssessmentAsync();
                        history.push(RoutePath.AssessmentsClosed);
                        showSuccessToaster(`${UIStrings.RecruitmentHasBeenClosed(assessmentName)}.`);
                    } catch {
                        showFailToaster();
                    }
                }}
            />
        </>
    );
}

export default RecruitmentMenuButton;

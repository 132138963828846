import { Location, useLocation, useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { InvitationPageContextAPI, InvitationPageContextState } from './InvitationPageContext';
import { AssessmentStatuses } from '../../../../Models/AssessmentData';
import InviteCandidatesPageRouteParams from '../Utils/InviteCandidatesPageRouteParams';
import { useAssessmentClient, useJobAdClient } from '../../../../Hooks/ClientHooks';
import RoutePath from '../../../../Routing/RoutePath';
import { InviteApplicantsPageLocationState } from '../InviteCandidatesPage';
import useRouteMatch from '../../../../Hooks/useRouteMatch';

function useInvitationPageContextValue(): InvitationPageContextAPI {
    const routeMatch = useRouteMatch([
        RoutePath.AttractApplicantsJobBoards,
        RoutePath.AttractApplicantsCareerPage,
        RoutePath.AttractApplicantsInvite,
    ]);

    // https://github.com/remix-run/react-router/pull/7326#issuecomment-626418225
    const location = useLocation() as Location<InviteApplicantsPageLocationState>;
    const [state, setState] = useState<InvitationPageContextState>({
        specsLoading: true,
        jobAdIdLoading: true,
        jobAdId: '',
        recruitmentName: '',
        recruitmentStatus: AssessmentStatuses.NonPublic,
        activeTab: routeMatch || RoutePath.AttractApplicantsJobBoards,
        returnURL: location?.state?.from || RoutePath.CompanyRoot,
        allowDialog: location?.state?.allowDialog || false,
    });
    const {
        specsLoading,
        jobAdIdLoading,
        jobAdId,
        recruitmentName,
        recruitmentStatus,
        activeTab,
        returnURL,
        allowDialog,
    } = state;
    const { recruitmentId } = useParams<Partial<InviteCandidatesPageRouteParams>>();
    const recruitmentClient = useAssessmentClient();
    const jobAdClient = useJobAdClient();

    useEffect(() => {
        if (recruitmentId) {
            const doLoadRecruitment = async () => {
                const specification = await recruitmentClient.getSpecificationAsync(recruitmentId);
                setState((prevState) => ({
                    ...prevState,
                    specsLoading: false,
                    recruitmentName: specification.name,
                    recruitmentStatus: specification.assessmentStatus,
                }));
            };
            doLoadRecruitment();
        }
    }, []);

    useEffect(() => {
        if (recruitmentId) {
            const doLoadJobAdId = async () => {
                try {
                    const id = await jobAdClient.getJobAdIdByRecruitmentIdAsync(recruitmentId);
                    setState((prevState) => ({
                        ...prevState,
                        jobAdIdLoading: false,
                        jobAdId: id,
                    }));
                } catch (error) {
                    setState((prevState) => ({
                        ...prevState,
                        jobAdIdLoading: false,
                        activeTab: RoutePath.AttractApplicantsInvite,
                    }));
                }
            };
            doLoadJobAdId();
        }
    }, []);

    return {
        specsLoading,
        jobAdIdLoading,
        jobAdId,
        recruitmentName,
        recruitmentStatus,
        activeTab,
        setActiveTab: (tab: string) => {
            setState((prevState) => ({ ...prevState, activeTab: tab }));
        },
        returnURL,
        allowDialog,
    };
}

export default useInvitationPageContextValue;

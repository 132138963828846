import { useTheme } from '@mui/material';
import { Box, Divider, Typography } from '@talentmesh/core';
import React from 'react';
import { CreatedAssessmentsState } from '../../../../../../Models/CreatedAssessmentsState';
import UIStrings from '../../../../../../Utils/UIStrings';
import { useAssessmentsOverviewContext } from '../../../Contexts/AssessmentsOverviewContext';

const HeaderSection = () => {
    const theme = useTheme();
    const { createdAssessmentsState } = useAssessmentsOverviewContext();
    const hasAnyAssessments = createdAssessmentsState === CreatedAssessmentsState.HasAnyAssessments;

    if (hasAnyAssessments) {
        return (
            <Typography variant="h5" color={theme.palette.text.menuTitle} marginBottom={theme.spacing(2.5)}>
                {UIStrings.YouDontHaveActiveRecruitments}
            </Typography>
        );
    }

    return (
        <Box fontWeight={theme.typography.fontWeightBold}>
            <Typography variant="h2" sx={{ color: theme.palette.primary.main, textAlign: 'center' }}>
                {UIStrings.WelcomeToTalentMeshGetStartedInJust5Min}
            </Typography>
            <Divider sx={{ mt: theme.spacing(1.25) }} />
        </Box>
    );
};

export default HeaderSection;

import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { ChevronRightIcon } from '@talentmesh/icons';
import ProcessingState from '../../../../../../Models/ProcessingState';
import UIStrings from '../../../../../../Utils/UIStrings';
import NextStepButton from '../../../Components/NextStepButton';
import { StepperNavigationButtons } from '../../../Components/StepperNavigationButtons';
import StepProps from '../../StepProps';
import { JobDetailsFormValues, getErrorFieldNames } from '../JobDetailsStepUtils';
import { useModifyRecruitmentContext } from '../../../Contexts/ModifyRecruitmentContext';
import useModifyRecruitmentContextHook from '../../../Contexts/useModifyRecruitmentContextHook';

function JobDetailsStepNavigation({ activeStep, handleBack }: StepProps): JSX.Element {
    const action = useModifyRecruitmentContext();
    const { processingState } = useModifyRecruitmentContextHook(action);
    const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
    const { values } = useFormikContext<JobDetailsFormValues>();

    useEffect(() => {
        const fieldNames = getErrorFieldNames(values);

        setNextButtonDisabled(fieldNames.length > 0);
    }, [values]);

    return (
        <StepperNavigationButtons
            activeStep={activeStep}
            handleBack={handleBack}
            nextButtonComponent={
                <NextStepButton
                    disabled={nextButtonDisabled}
                    loading={processingState === ProcessingState.Processing}
                    endIcon={<ChevronRightIcon />}
                >
                    {UIStrings.Next}
                </NextStepButton>
            }
        />
    );
}

export default JobDetailsStepNavigation;

import { ApplicantInformationAnswerDTO } from '../DTO/Assessments/ApplicantInformationAnswerDTO';
import { ApplicantProfileDTO } from '../DTO/Assessments/ApplicantProfileDTO';
import { CandidateAssessmentResultsDTO } from '../DTO/Assessments/CandidateAssessmentResultsDTO';
import InformationTypes from '../DTO/Assessments/InformationTypes';
import getFullCountryName from '../Pages/Candidates/Results/Components/Tabs/Details/Components/RecruitmentInformation/Utils/getFullCountryName';
import autoMapping from '../Utils/autoMapping';
import mapLocaleCodeToLanguageName from '../Utils/mapLocaleCodeToLanguageName';
import UIStrings from '../Utils/UIStrings';
import ApplicantInformation from './ApplicantInformation';
import ApplicantInformationType from './ApplicantInformationType';
import { ApplicantProfile, DefaultApplicantProfile } from './ApplicantProfile';
import { CandidateStatuses } from './CandidateOverview';
import { TestTypes } from './Configuration';
import { DefaultEducation } from './Education';
import { EmotionalIntelligenceScore } from './EmotionalIntelligenceScore';
import { DefaultExperience } from './Experience';
import { ExperienceCriteriaFinalScore } from './ExperienceCriteriaFinalScore';
import { PersonalityScore } from './PersonalityScore';
import { TalentScore } from './TalentScore';
import { TestScore } from './TestScore';

export interface CandidateAssessmentResults {
    companyId: string;
    assessmentId: string;
    candidateId: string;
    candidateFirstName: string;
    candidateLastName: string;
    candidateEmail: string;
    name: string;

    invitedAt: string;
    completedAt?: string;
    lastViewedAt?: string;

    talentScore?: TalentScore;
    experienceCriteriaScore?: ExperienceCriteriaFinalScore;
    personalityScore?: PersonalityScore;
    aptitudeScore?: TestScore;
    skillsScore?: TestScore;
    emotionalIntelligenceScore?: EmotionalIntelligenceScore;

    candidateStatus: CandidateStatuses;
    isFavorite: boolean;
    includedTests: Array<TestTypes>;
    includesExperienceCriteria: boolean;
    jobFunctionId: number;
    isInternal: boolean;
    isReset: boolean;

    additionalInformation: Array<ApplicantInformation>;
    applicantProfile: ApplicantProfile;

    locale: string | null;
}

export const DefaultCandidateAssessmentResults: CandidateAssessmentResults = {
    companyId: '',
    assessmentId: '',
    candidateId: '',
    candidateFirstName: '',
    candidateLastName: '',
    candidateEmail: '',
    name: '',

    invitedAt: '',
    completedAt: '',
    lastViewedAt: '',

    candidateStatus: CandidateStatuses.Invited,
    isFavorite: false,
    includedTests: [],
    includesExperienceCriteria: false,
    jobFunctionId: 0,
    isInternal: false,
    isReset: false,

    additionalInformation: [],
    applicantProfile: DefaultApplicantProfile,

    locale: null,
};

function getFormattedAnswer(type: ApplicantInformationType, answer: string, isUnemployed: boolean): string {
    switch (type) {
        case ApplicantInformationType.ReasonForLeaving:
        case ApplicantInformationType.NoticePeriod:
            return isUnemployed ? UIStrings.ImCurrentlyUnemployed : answer;
        default:
            return answer;
    }
}

export function mapApplicantProfileDTO2Model(name: string, dto: ApplicantProfileDTO): ApplicantProfile {
    const model = autoMapping(dto, DefaultApplicantProfile);

    model.name = name;

    const { experiences, educations, location, legacyLocation } = dto;

    model.experiences = (experiences || []).map((x) => autoMapping(x, DefaultExperience));
    model.educations = (educations || []).map((x) => autoMapping(x, DefaultEducation));

    model.location = location?.description || (legacyLocation != null ? getFullCountryName(legacyLocation) : undefined);

    return model;
}

export function mapCandidateAssessmentResultsDTO2Model(dto: CandidateAssessmentResultsDTO): CandidateAssessmentResults {
    const { applicantProfile, additionalInformation } = dto;

    const name = `${applicantProfile.firstName} ${applicantProfile.lastName}`;

    let isUnemployed = false;
    const isEmployedInfo = additionalInformation.filter((x) => x.informationType === InformationTypes.IsEmployed);
    if (isEmployedInfo?.length === 1) {
        isUnemployed = isEmployedInfo[0].answer.toLowerCase() === 'isUnemployed'.toLowerCase();
    }
    const matched = ({ informationType }: ApplicantInformationAnswerDTO): boolean =>
        informationType !== InformationTypes.IsEmployed;
    return {
        ...dto,
        additionalInformation: additionalInformation
            .filter((item) => matched(item))
            .map(({ informationType, answer }) => {
                const infoType = informationType as unknown as ApplicantInformationType;
                return {
                    informationType: infoType,
                    answer: getFormattedAnswer(infoType, answer, isUnemployed),
                };
            }),
        candidateId: applicantProfile.id,
        candidateEmail: applicantProfile.email,
        candidateFirstName: applicantProfile.firstName,
        candidateLastName: applicantProfile.lastName,
        name,
        applicantProfile: mapApplicantProfileDTO2Model(name, applicantProfile),
        locale: mapLocaleCodeToLanguageName(dto.locale),
    };
}

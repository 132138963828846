import React from 'react';
import { StepperNavigationButtons } from '../../../Components/StepperNavigationButtons';
import StepProps from '../../StepProps';
import JobAdNavigationButtons from './JobAdNavigationButtons';

function JobAdStepNavigation({ activeStep, handleBack }: StepProps): JSX.Element {
    return (
        <StepperNavigationButtons
            activeStep={activeStep}
            handleBack={handleBack}
            nextButtonComponent={<JobAdNavigationButtons />}
        />
    );
}

export default JobAdStepNavigation;

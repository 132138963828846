import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { FormikDropdown } from '@talentmesh/forms';
import UIStrings from '../../../../../../Utils/UIStrings';
import { AssessmentSettings } from '../../../../../../Models/Configuration';
import { JobCategory } from '../../../../../../Models/JobCategory';
import { RecruitmentActionTypes, useModifyRecruitmentContext } from '../../../Contexts/ModifyRecruitmentContext';
import {
    findJobCategory,
    mapCategoriesToOptions,
    mapJobFunctionsToOptions,
} from '../../../../../../Utils/JobFunctionSelectionUtils';
import { JobDetailsFieldTypes, JobDetailsFormValues } from '../JobDetailsStepUtils';

interface Props {
    assessmentConfiguration: AssessmentSettings;
}

const JobFunctionSelection = ({ assessmentConfiguration }: Props): JSX.Element => {
    const action = useModifyRecruitmentContext();
    const {
        values: { jobCategoryId },
        setFieldValue,
        setFieldTouched,
        dirty,
    } = useFormikContext<JobDetailsFormValues>();

    const resetFieldState = (fieldName: string) => {
        setFieldValue(fieldName, '');
        setFieldTouched(fieldName, false);
    };

    const [jobCategory, setJobCategory] = useState<JobCategory | undefined>(
        findJobCategory(assessmentConfiguration.jobCategories, jobCategoryId),
    );

    useEffect(() => {
        const initialJobCategory = findJobCategory(assessmentConfiguration.jobCategories, jobCategoryId);
        setJobCategory(initialJobCategory);

        if (dirty) {
            resetFieldState(JobDetailsFieldTypes.JobFunctionId);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobCategoryId]);

    const categoryOptions = mapCategoriesToOptions(
        assessmentConfiguration.jobCategories.filter((category) => !category.isDeprecated),
    );
    const jobFunctionOptions = mapJobFunctionsToOptions(
        jobCategory?.jobFunctions.filter((func) => !func.isDeprecated) ?? [],
    );

    return (
        <>
            <FormikDropdown
                label={`${UIStrings.JobCategory}*`}
                name={JobDetailsFieldTypes.JobCategoryId}
                data-cy={JobDetailsFieldTypes.JobCategoryId}
                options={categoryOptions}
                disabled={action === RecruitmentActionTypes.Edit}
            />
            <FormikDropdown
                label={`${UIStrings.JobFunction}*`}
                name={JobDetailsFieldTypes.JobFunctionId}
                data-cy={JobDetailsFieldTypes.JobFunctionId}
                options={jobFunctionOptions}
                disabled={jobCategory === undefined || action === RecruitmentActionTypes.Edit}
            />
        </>
    );
};

export default JobFunctionSelection;

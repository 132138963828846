import { useTheme } from '@mui/material';
import { Button, ButtonProps } from '@talentmesh/core';
import React from 'react';

const LinkButton = React.forwardRef<HTMLButtonElement, React.PropsWithChildren<ButtonProps>>(
    ({ children, ...props }: React.PropsWithChildren<ButtonProps>, ref) => {
        const theme = useTheme();

        return (
            <Button variant="outlined" sx={{ backgroundColor: theme.palette.common.white }} {...props} ref={ref}>
                {children}
            </Button>
        );
    },
);

export default LinkButton;

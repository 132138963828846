import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AppTabs, Box, Stack, Tab, TMTopBar } from '@talentmesh/core';
import RoutePath from '../../Routing/RoutePath';
import UIStrings from '../../Utils/UIStrings';
import TopBarConsts from './TopBarConsts';
import ProfileMenuButton from './TopBarMenu/ProfileMenuButton';
import useCompanyIdRoute from '../../Routing/useCompanyIdRoute';

interface TopBarProps {
    allowTabChange?: boolean;
}

const TopBar = ({ allowTabChange }: TopBarProps) => {
    const theme = useTheme();
    const location = useLocation();

    const [tabValue, setTabValue] = useState<string | boolean>(false);

    const rootPath = useCompanyIdRoute(RoutePath.CompanyRoot);
    const createRecruitmentPath = useCompanyIdRoute(RoutePath.CreateAssessment);
    useEffect(() => {
        switch (location.pathname) {
            case createRecruitmentPath:
                setTabValue(createRecruitmentPath);
                break;
            case rootPath:
                setTabValue(rootPath);
                break;
            default:
                // MUI uses false as an indicator that none of the tabs
                // are selected
                setTabValue(false);
        }
    }, [location]);

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        if (allowTabChange) {
            setTabValue(newValue);
        }
    };

    return (
        <TMTopBar position="static" logoClickURL={rootPath} logoLinkComponent={Link} logoColor="primary">
            <AppTabs sx={{ flexGrow: 1 }} value={tabValue} onChange={handleChange}>
                <Tab label={UIStrings.MyRecruitments} value={rootPath} component={Link} to={rootPath} />
                <Tab
                    label={UIStrings.CreateRecruitment}
                    value={createRecruitmentPath}
                    component={Link}
                    to={createRecruitmentPath}
                />
            </AppTabs>
            <Box sx={{ flexGrow: 1 }} />
            <Stack data-cy="TopBarMenuSection" spacing={3} direction="row">
                <ProfileMenuButton />
            </Stack>
            <Box sx={{ paddingRight: theme.spacing(TopBarConsts.rightPadding) }} />
        </TMTopBar>
    );
};

export default TopBar;

import React from 'react';
import { Snackbar } from '@talentmesh/core';
import { Box } from '@mui/material';
import RecruitmentCreatedNotification from './RecruitmentCreatedNotification';
import { useRecruitmentCreatedNotificationContext } from '../../../Context/RecruitmentCreatedNotificationContext';

const RecruitmentCreatedNotificationTrigger = () => {
    const { open, title, text, recruitmentId, companyId, setOpen } = useRecruitmentCreatedNotificationContext();

    const closeNotification = () => {
        setOpen(false);
    };

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        closeNotification();
    };

    return (
        <Snackbar data-cy="recruitmentCreatedNotification" autoHideDuration={10000} open={open} onClose={handleClose}>
            <Box>
                <RecruitmentCreatedNotification
                    title={title}
                    text={text}
                    onClose={closeNotification}
                    recruitmentId={recruitmentId}
                    companyId={companyId}
                />
            </Box>
        </Snackbar>
    );
};

export default RecruitmentCreatedNotificationTrigger;

import React from 'react';
import { Box, Paper, Stack, Typography } from '@talentmesh/core';
import { useTheme } from '@mui/material/styles';
import UIStrings from '../../../../../Utils/UIStrings';
import { RelevantQuestion } from '../../../../../Models/RelevantQuestion';
import RelevantQuestionItem from './Components/RelevantQuestionItem';

interface RelevantQuestionsProps {
    items: RelevantQuestion[];
}
function RelevantQuestions({ items }: RelevantQuestionsProps): JSX.Element {
    const theme = useTheme();

    return (
        <Paper
            elevation={0}
            sx={{
                borderRadius: theme.shape.mediumBorderRadius,
                paddingBottom: 3.5,
            }}
        >
            <Stack direction="column">
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ paddingTop: 3.5, paddingBottom: 2.5 }}
                >
                    <Typography variant="h3" color={theme.palette.secondary.main}>
                        {UIStrings.RelevantQuestionsToAskDuringTheInterviewProcess}
                    </Typography>
                </Box>
                <Stack direction="column" spacing={2} sx={{ paddingRight: 2.5, paddingLeft: 2.5 }}>
                    {items.map((item) => (
                        <RelevantQuestionItem key={item.characteristicName} {...item} />
                    ))}
                </Stack>
            </Stack>
        </Paper>
    );
}

export default RelevantQuestions;

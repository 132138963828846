import React from 'react';
import { AssessmentStatuses } from '../../../../../Models/AssessmentData';
import EmptyClosedAssessmentsOverview from './EmptyClosedAssessmentsOverview';
import RecruitmentOnboardingSection from '../RecruitmentOnboarding/RecruitmentOnboardingSection';
import { useAssessmentsOverviewContext } from '../../Contexts/AssessmentsOverviewContext';

const EmptyAssessmentsOverview = (): JSX.Element => {
    const { status } = useAssessmentsOverviewContext();
    return (
        <>
            {status === AssessmentStatuses.Public && <RecruitmentOnboardingSection />}
            {status === AssessmentStatuses.Closed && <EmptyClosedAssessmentsOverview />}
        </>
    );
};

export default EmptyAssessmentsOverview;

import { useTheme } from '@mui/material/styles';
import { Container, RoundButtonTab, Search, Stack, TabContext, TabList, Typography } from '@talentmesh/core';
import React from 'react';
import { Link, generatePath } from 'react-router-dom';
import RoutePath from '../../../../Routing/RoutePath';
import UIStrings from '../../../../Utils/UIStrings';
import { useAssessmentsOverviewContext } from '../Contexts/AssessmentsOverviewContext';
import CreateRecruitmentButton from './CreateRecruitmentButton';
import useCompanyId from '../../../../Hooks/UseCompanyId';

function AssessmentsOverviewHeader() {
    const theme = useTheme();
    const { searchByName, loading, assessments, search, currentTab } = useAssessmentsOverviewContext();
    const showSearch = search || loading || assessments.length > 0;
    const width = theme.spacing(12.5);
    const companyId = useCompanyId();

    const active = generatePath(RoutePath.AssessmentsActive, { companyId });
    const closed = generatePath(RoutePath.AssessmentsClosed, { companyId });

    return (
        <Stack
            sx={{
                backgroundColor: theme.palette.background.default,
                borderBottom: `1px solid ${theme.palette.border.main}`,
            }}
        >
            <Container disableGutters>
                <Typography
                    mt={theme.spacing(3.75)}
                    mb={theme.spacing(1.5)}
                    variant="h4"
                    color={theme.palette.text.menuTitle}
                >
                    {UIStrings.MyRecruitments}
                </Typography>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <TabContext value={currentTab}>
                        <TabList sx={{ placeSelf: 'end' }}>
                            <RoundButtonTab
                                label={UIStrings.Active}
                                value={active}
                                component={Link}
                                to={active}
                                sx={{ width }}
                            />
                            <RoundButtonTab
                                label={UIStrings.Closed}
                                value={closed}
                                component={Link}
                                to={closed}
                                sx={{ width }}
                            />
                        </TabList>
                    </TabContext>
                    <Stack direction="row" spacing={theme.spacing(1.25)} mb={theme.spacing(1)}>
                        <Search
                            placeholder={UIStrings.SearchRecruitments}
                            sx={{
                                paddingLeft: theme.spacing(30),
                                minWidth: theme.spacing(40),
                                display: showSearch ? 'block' : 'none',
                            }}
                            onSearch={searchByName}
                        />
                        <CreateRecruitmentButton />
                    </Stack>
                </Stack>
            </Container>
        </Stack>
    );
}

export default AssessmentsOverviewHeader;

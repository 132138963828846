import { Link, SpanTypography, Stack, Typography } from '@talentmesh/core';
import React from 'react';
import { calendlyOnboardingUrl } from '../../../../../../AppSettings';
import { CreatedAssessmentsState } from '../../../../../../Models/CreatedAssessmentsState';
import UIStrings from '../../../../../../Utils/UIStrings';
import { useAssessmentsOverviewContext } from '../../../Contexts/AssessmentsOverviewContext';
import CreateRecruitmentButton from '../../CreateRecruitmentButton';

const FooterSection = () => {
    const { createdAssessmentsState } = useAssessmentsOverviewContext();
    const hasAnyAssessments = createdAssessmentsState === CreatedAssessmentsState.HasAnyAssessments;

    if (!hasAnyAssessments) {
        return null;
    }

    return (
        <Stack spacing={1.875} sx={{ alignItems: 'center' }}>
            <CreateRecruitmentButton label={UIStrings.CreateARecruitmentIn5Min} hideIcon />
            <Typography variant="body2" color="secondary" data-cy="OnboardingScheduleDemoSection">
                <SpanTypography>{`${UIStrings.NeedMoreInformation} `}</SpanTypography>
                <Link href={calendlyOnboardingUrl} fontWeight="700" target="_blank">
                    {UIStrings.Demo}
                </Link>
            </Typography>
        </Stack>
    );
};

export default FooterSection;

import React from 'react';
import { ChevronRightIcon } from '@talentmesh/icons';
import ProcessingState from '../../../../../../Models/ProcessingState';
import UIStrings from '../../../../../../Utils/UIStrings';
import NextStepButton from '../../../Components/NextStepButton';
import { StepperNavigationButtons } from '../../../Components/StepperNavigationButtons';
import StepProps from '../../StepProps';
import { useModifyRecruitmentContext } from '../../../Contexts/ModifyRecruitmentContext';
import useModifyRecruitmentContextHook from '../../../Contexts/useModifyRecruitmentContextHook';

function RequestInformationStepNavigation({ activeStep, handleBack }: StepProps): JSX.Element {
    const action = useModifyRecruitmentContext();
    const { processingState } = useModifyRecruitmentContextHook(action);

    return (
        <StepperNavigationButtons
            activeStep={activeStep}
            handleBack={handleBack}
            nextButtonComponent={
                <NextStepButton loading={processingState === ProcessingState.Processing} endIcon={<ChevronRightIcon />}>
                    {UIStrings.Next}
                </NextStepButton>
            }
        />
    );
}

export default RequestInformationStepNavigation;

import React from 'react';
import { Label, Stack } from '@talentmesh/core';
import { FormikAutocompleteDropdown } from '@talentmesh/forms';
import { JobDetailsFieldTypes } from '../JobDetailsStepUtils';
import UIStrings from '../../../../../../Utils/UIStrings';
import { languages } from '../../../../../../Utils/JobAdLanguage/JobAdLanguages';
import { RecruitmentActionTypes, useModifyRecruitmentContext } from '../../../Contexts/ModifyRecruitmentContext';

function JobDescriptionLanguage(): JSX.Element {
    const action = useModifyRecruitmentContext();

    return (
        <Stack flex={1}>
            <Label>{UIStrings.JobDescriptionLanguage}</Label>
            <FormikAutocompleteDropdown
                name={JobDetailsFieldTypes.JobDescriptionLanguage}
                data-cy={JobDetailsFieldTypes.JobDescriptionLanguage}
                autocompleteOptions={languages}
                disabled={action === RecruitmentActionTypes.Edit}
                disableClearable
                freeSolo={false}
                disableErrorMessage
            />
        </Stack>
    );
}

export default JobDescriptionLanguage;

import React from 'react';
import { Form, Formik } from 'formik';
import { SelectTestsStepperNavigationButtons } from './Components/StepperNavigationButtons/SelectTestsStepperNavigationButtons';
import StepProps from '../StepProps';
import { useCreateRecruitmentContext } from '../../Create/Contexts/CreateRecruitmentContext';
import { TestTypes } from '../../../../../Models/Configuration';
import TestsPanel from './Components/TestsPanel/TestsPanel';
import SelectTestsStepHeader from './Components/StepHeader/SelectTestsStepHeader';
import NextButtonComponent from './Components/StepperNavigationButtons/Components/NextButtonComponent';
import EstimatedTime from './Components/StepperNavigationButtons/Components/EstimatedTime';
import { useNotificationContext } from '../../../../../Context/NotificationContext';
import UIStrings from '../../../../../Utils/UIStrings';

export interface TestSelectionFormValues {
    isAptitudeSelected: boolean;
    isEmotionalIntelligenceSelected: boolean;
    isSkillsSelected: boolean;
    isPersonalitySelected: boolean;
    testLanguageField: string;
}

const SelectTestsStep = ({ activeStep, handleNext, handleBack }: StepProps): JSX.Element => {
    const { includedTests, setIncludedTests, testLanguage, setTestLanguage } = useCreateRecruitmentContext();

    const hasTest = (test: TestTypes): boolean => includedTests.indexOf(test) > -1;
    const { showFailToaster } = useNotificationContext();

    const initialValues: TestSelectionFormValues = {
        isAptitudeSelected: hasTest(TestTypes.Aptitude),
        isEmotionalIntelligenceSelected: hasTest(TestTypes.EmotionalIntelligence),
        isSkillsSelected: hasTest(TestTypes.Skills),
        isPersonalitySelected: hasTest(TestTypes.Personality),
        testLanguageField: testLanguage,
    };

    const submitHandler = async (values: TestSelectionFormValues) => {
        const tests: TestTypes[] = [];
        if (values.isAptitudeSelected) {
            tests.push(TestTypes.Aptitude);
        }
        if (values.isEmotionalIntelligenceSelected) {
            tests.push(TestTypes.EmotionalIntelligence);
        }
        if (values.isPersonalitySelected) {
            tests.push(TestTypes.Personality);
        }
        if (values.isSkillsSelected) {
            tests.push(TestTypes.Skills);
        }

        if (tests.length === 0) {
            showFailToaster(UIStrings.PleaseSelectAtLeastOneTestToContinue);
            return;
        }

        setIncludedTests(tests);
        setTestLanguage(values.testLanguageField);

        if (handleNext) {
            handleNext();
        }
    };

    return (
        <Formik initialValues={initialValues} onSubmit={submitHandler}>
            {(formik) => (
                <Form>
                    <SelectTestsStepHeader />
                    <TestsPanel helpers={formik} />
                    <SelectTestsStepperNavigationButtons
                        activeStep={activeStep}
                        handleBack={handleBack}
                        nextButtonComponent={<NextButtonComponent />}
                        centeredComponent={<EstimatedTime />}
                    />
                </Form>
            )}
        </Formik>
    );
};

export default SelectTestsStep;

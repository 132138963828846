import React from 'react';
import { Paper } from '@talentmesh/core';
import { useTheme } from '@mui/material';
import RecruitmentCreatedNotificationHeader from './RecruitmentCreatedNotificationHeader';
import RecruitmentCreatedNotificationBody from './RecruitmentCreatedNotificationBody';
import RecruitmentCreatedNotificationAction from './RecruitmentCreatedNotificationAction';

interface RecruitmentCreatedNotificationProps {
    title: string;
    text: React.ReactNode;
    recruitmentId: string;
    companyId: string;
    onClose: () => void;
}

const RecruitmentCreatedNotification = ({
    title,
    text,
    recruitmentId,
    companyId,
    onClose,
}: RecruitmentCreatedNotificationProps) => {
    const theme = useTheme();
    return (
        <Paper
            elevation={24}
            sx={{ pt: theme.spacing(2), px: theme.spacing(3), pb: theme.spacing(2.5), maxWidth: theme.spacing(62) }}
        >
            <RecruitmentCreatedNotificationHeader title={title} onClose={onClose} />
            <RecruitmentCreatedNotificationBody text={text} />
            <RecruitmentCreatedNotificationAction
                recruitmentId={recruitmentId}
                companyId={companyId}
                onClose={onClose}
            />
        </Paper>
    );
};

export default RecruitmentCreatedNotification;

import React from 'react';
import { ScoreItem, Stack } from '@talentmesh/core';
import UIStrings from '../../../../../Utils/UIStrings';
import RelevantQuestions from '../RelevantQuestions/RelevantQuestions';
import { useCandidatesResultsContext } from '../../Contexts/CandidateResultsContext';
import PersonalityScoreDetails from './Personality/PersonalityScoreDetails';
import ScoreOverview from './Components/ScoreOverview';

function PersonalityTab(): JSX.Element | null {
    const { results, jobFunction } = useCandidatesResultsContext();
    const { personalityScore, candidateFirstName } = results;

    if (personalityScore) {
        const { relevantQuestions, percentileScore, percentileLevel, testValidity } = personalityScore;

        const overall: ScoreItem = {
            level: percentileLevel,
            name: UIStrings.OverallScore,
            value: percentileScore,
        };

        const scores: ScoreItem[] = personalityScore.factors.map((factor) => {
            return {
                level: factor.percentileLevel,
                name: factor.factorName,
                value: factor.percentileScore,
            };
        });

        return (
            <Stack spacing={2.5} sx={{ marginBottom: 2.5 }}>
                <ScoreOverview
                    scoreOverviewTitle={UIStrings.PersonalityScoreOverview}
                    scoreOverviewDescription={UIStrings.AnOverallScoreIsCalculated(jobFunction)}
                    candidateFirstName={candidateFirstName}
                    scores={scores}
                    overall={overall}
                    testValidity={testValidity}
                />
                <PersonalityScoreDetails />
                {relevantQuestions.length > 0 && <RelevantQuestions items={relevantQuestions} />}
            </Stack>
        );
    }
    return null;
}

export default PersonalityTab;

import { useTheme } from '@mui/material';
import { FormikTextField } from '@talentmesh/forms';
import { useFormikContext } from 'formik';
import React from 'react';
import ProcessingState from '../../../../../../Models/ProcessingState';
import UIStrings from '../../../../../../Utils/UIStrings';
import { useModifyRecruitmentContext } from '../../../Contexts/ModifyRecruitmentContext';
import useModifyRecruitmentContextHook from '../../../Contexts/useModifyRecruitmentContextHook';
import { JobAdFieldTypes } from '../JobAdStepUtils';
import WingsIcon from './WingsIcon';

function JobAdTitle(): JSX.Element {
    const action = useModifyRecruitmentContext();
    const { processingState } = useModifyRecruitmentContextHook(action);
    const loading = processingState === ProcessingState.Processing;
    const { isSubmitting } = useFormikContext();
    const theme = useTheme();

    return (
        <FormikTextField
            label={UIStrings.JobAdTitle}
            name={JobAdFieldTypes.Title}
            data-cy={JobAdFieldTypes.Title}
            placeholder={loading ? '' : UIStrings.CustomizeTheJobPostTitle}
            InputProps={{
                startAdornment: loading ? <WingsIcon marginRight={theme.spacing(2)} /> : null,
            }}
            disabled={isSubmitting || loading}
            sx={{
                '& .MuiInputBase-root.Mui-disabled': {
                    backgroundColor: theme.palette.common.white,
                },
            }}
        />
    );
}

export default JobAdTitle;

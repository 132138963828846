import React, { ReactNode, createContext, useContext, useState } from 'react';
import UIStrings from '../Utils/UIStrings';
import NonPublicRecruitmentCreatedNotificationBody from '../Components/RecruitmentCreatedNotificationSystem/RecruitmentCreatedNotification/RecruitmentCreatedNotificationBodyVariants/NonPublicRecruitmentCreatedNotificationBody';
import PublicRecruitmentCreatedNotificationBody from '../Components/RecruitmentCreatedNotificationSystem/RecruitmentCreatedNotification/RecruitmentCreatedNotificationBodyVariants/PublicRecruitmentCreatedNotificationBody';
import PublicRecruitmentCreatedNotificationBodyNoCareerPage from '../Components/RecruitmentCreatedNotificationSystem/RecruitmentCreatedNotification/RecruitmentCreatedNotificationBodyVariants/PublicRecruitmentCreatedNotificationBodyNoCareerPage';

type RecruitmentCreatedNotificationContextType = {
    open: boolean;
    title: string;
    text: React.ReactNode;
    recruitmentId: string;
    companyId: string;
    setOpen: (value: boolean) => void;
    showNonPublicRecruitmentCreatedNotification: (recruitmentId: string, companyId: string) => void;
    showPublicRecruitmentCreatedNotification: (recruitmentId: string, companyId: string) => void;
    showPublicRecruitmentCreatedNoCareerPageNotification: (recruitmentId: string, companyId: string) => void;
};

export const RecruitmentCreatedNotificationContext = createContext<
    RecruitmentCreatedNotificationContextType | undefined
>(undefined);

const RecruitmentCreatedNotificationContextProvider = RecruitmentCreatedNotificationContext.Provider;

interface NotificationProviderProps {
    children: ReactNode;
}

interface RecruitmentCreatedNotification {
    open: boolean;
    title: string;
    text: React.ReactNode;
    recruitmentId: string;
    companyId: string;
}

const DefaultRecruitmentCreatedNotification: RecruitmentCreatedNotification = {
    open: false,
    title: '',
    text: null,
    recruitmentId: '',
    companyId: '',
};

export const RecruitmentCreatedNotificationProvider = ({ children }: NotificationProviderProps): JSX.Element => {
    const [state, setState] = useState<RecruitmentCreatedNotification>(DefaultRecruitmentCreatedNotification);

    const showNonPublicRecruitmentCreatedNotification = (recruitmentId: string, companyId: string) =>
        setState((prev) => ({
            ...prev,
            open: true,
            title: UIStrings.NonPublicRecruitmentCreated,
            text: <NonPublicRecruitmentCreatedNotificationBody />,
            recruitmentId,
            companyId,
        }));

    const showPublicRecruitmentCreatedNotification = (recruitmentId: string, companyId: string) =>
        setState((prev) => ({
            ...prev,
            open: true,
            title: UIStrings.PublicRecruitmentCreated,
            text: <PublicRecruitmentCreatedNotificationBody />,
            recruitmentId,
            companyId,
        }));

    const showPublicRecruitmentCreatedNoCareerPageNotification = (recruitmentId: string, companyId: string) =>
        setState((prev) => ({
            ...prev,
            open: true,
            title: UIStrings.PublicRecruitmentCreatedNoCareerPage,
            text: <PublicRecruitmentCreatedNotificationBodyNoCareerPage />,
            recruitmentId,
            companyId,
        }));

    return (
        <RecruitmentCreatedNotificationContextProvider
            value={{
                open: state.open,
                setOpen: (open: boolean) => setState((prev) => ({ ...prev, open })),
                title: state.title,
                text: state.text,
                recruitmentId: state.recruitmentId,
                companyId: state.companyId,
                showNonPublicRecruitmentCreatedNotification,
                showPublicRecruitmentCreatedNotification,
                showPublicRecruitmentCreatedNoCareerPageNotification,
            }}
        >
            {children}
        </RecruitmentCreatedNotificationContextProvider>
    );
};

export function useRecruitmentCreatedNotificationContext() {
    const context = useContext(RecruitmentCreatedNotificationContext);
    if (!context) {
        throw new Error(
            'RecruitmentCreatedNotificationContex must be used within the RecruitmentCreatedNotificationContex.Provider',
        );
    }
    return context;
}

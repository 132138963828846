import React from 'react';
import { LoadingButton, RadioGroup, Stack } from '@talentmesh/core';
import { useTheme } from '@mui/material';
import StepProps from '../StepProps';
import { useCreateRecruitmentContext } from '../../Create/Contexts/CreateRecruitmentContext';
import { AssessmentStatus } from '../../../../../Models/Configuration';
import { useNotificationContext } from '../../../../../Context/NotificationContext';
import PostJobStepHeader from './Components/PostJobStepHeader';
import UIStrings from '../../../../../Utils/UIStrings';
import PostingOption from './Components/PostingOption';
import PublicOptionDescription from './Components/PublicOptionDescription';
import NonPublicOptionDescription from './Components/NonPublicOptionDescription';
import { StepperNavigationButtons } from '../../Components/StepperNavigationButtons';
import RoutePath from '../../../../../Routing/RoutePath';
import ProcessingState from '../../../../../Models/ProcessingState';
import { useRecruitmentCreatedNotificationContext } from '../../../../../Context/RecruitmentCreatedNotificationContext';
import useTalentMeshHistory from '../../../../../Routing/useTalentMeshHistory';
import useCompanyId from '../../../../../Hooks/UseCompanyId';

const PostJobStep = ({ activeStep, handleBack }: StepProps): JSX.Element => {
    const theme = useTheme();
    const history = useTalentMeshHistory();
    const {
        processingState,
        createRecruitmentAsync,
        getCareerPageStatus,
        recruitmentCreationOption,
        setRecruitmentCreationOption,
    } = useCreateRecruitmentContext();
    const {
        showNonPublicRecruitmentCreatedNotification,
        showPublicRecruitmentCreatedNotification,
        showPublicRecruitmentCreatedNoCareerPageNotification,
    } = useRecruitmentCreatedNotificationContext();

    const companyId = useCompanyId();
    const { showFailToaster } = useNotificationContext();

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRecruitmentCreationOption(event.target.value as AssessmentStatus);
    };

    const submitHandlerAsync = async () => {
        if (recruitmentCreationOption) {
            try {
                const isCareerPagePublished = await getCareerPageStatus();
                const recruitmentId = await createRecruitmentAsync(recruitmentCreationOption, companyId);
                history.push(RoutePath.AssessmentsActive);
                if (recruitmentCreationOption === 'Public') {
                    if (isCareerPagePublished) showPublicRecruitmentCreatedNotification(recruitmentId, companyId);
                    else showPublicRecruitmentCreatedNoCareerPageNotification(recruitmentId, companyId);
                } else if (recruitmentCreationOption === 'NonPublic') {
                    showNonPublicRecruitmentCreatedNotification(recruitmentId, companyId);
                }
            } catch (error) {
                showFailToaster();
            }
        }
    };

    return (
        <>
            <PostJobStepHeader />
            <RadioGroup row value={recruitmentCreationOption} onChange={handleRadioChange}>
                <Stack direction="row" sx={{ width: '100%', mb: theme.spacing(5) }} justifyContent="center">
                    <PostingOption
                        currentValue={recruitmentCreationOption}
                        label={UIStrings.Public}
                        value="Public"
                        description={<PublicOptionDescription />}
                        dataCy="publicOption"
                    />
                    <PostingOption
                        currentValue={recruitmentCreationOption}
                        label={UIStrings.NonPublic}
                        value="NonPublic"
                        description={<NonPublicOptionDescription />}
                        dataCy="nonPublicOption"
                    />
                </Stack>
            </RadioGroup>
            <StepperNavigationButtons
                activeStep={activeStep}
                handleBack={handleBack}
                nextButtonComponent={
                    <LoadingButton
                        size="large"
                        variant="contained"
                        disabled={!recruitmentCreationOption}
                        loading={processingState === ProcessingState.Processing}
                        onClick={submitHandlerAsync}
                    >
                        {UIStrings.CreateRecruitment}
                    </LoadingButton>
                }
            />
        </>
    );
};

export default PostJobStep;

export enum CreatedAssessmentsState {
    NoAssessments = 'NoAssessments',
    HasAnyAssessments = 'HasAnyAssessments',
}

export function mapCreatedAssessmentsState(value: number): CreatedAssessmentsState {
    switch (value) {
        case 100:
            return CreatedAssessmentsState.NoAssessments;
        default:
            return CreatedAssessmentsState.HasAnyAssessments;
    }
}

import { useTheme } from '@mui/material/styles';
import React from 'react';
import { Stack, Typography, Container } from '@talentmesh/core';
import UIStrings from '../../../../../Utils/UIStrings';

const EmptyClosedAssessmentsOverview = (): JSX.Element => {
    const theme = useTheme();

    return (
        <Stack direction="column" justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
            <Container maxWidth="sm">
                <Typography
                    sx={{ paddingBottom: theme.spacing(2) }}
                    variant="h5"
                    color={theme.palette.text.hint}
                    align="center"
                >
                    {UIStrings.YourDontHaveAnyCloseRecruitmentYet}
                </Typography>
            </Container>
        </Stack>
    );
};

export default EmptyClosedAssessmentsOverview;

import React, { ReactNode } from 'react';
import { Stack, Typography } from '@talentmesh/core';
import { useTheme } from '@mui/material/styles';

interface TestCardGroupProps {
    groupName: string;
    children: ReactNode;
}

function TestCardGroup({ groupName, children }: TestCardGroupProps): JSX.Element {
    const theme = useTheme();

    return (
        <Stack
            spacing={1.25}
            sx={{
                backgroundColor: theme.palette.background.aliciaBlue,
                border: `1px solid ${theme.palette.border.main}`,
                borderRadius: theme.shape.smallBorderRadius,
                padding: theme.spacing(2),
                alignItems: 'center',
            }}
        >
            <Typography variant="h4" sx={{ color: theme.palette.text.primary }}>
                {groupName}
            </Typography>
            <Stack direction="row" spacing={1.25}>
                {children}
            </Stack>
        </Stack>
    );
}

export default TestCardGroup;

import React from 'react';
import { Outlet, RouteObject } from 'react-router';
import AccountSettingsProvider from '../Context/AccountSettingsProvider';
import { CompanyProvider } from '../Context/CompanyContext';
import AccountSettingsPage from '../Pages/AccountSettings/AccountSettingsPage';
import CreateRecruitmentPage from '../Pages/Assessments/Modify/Create/CreateRecruitmentPage';
import EditRecruitmentPage from '../Pages/Assessments/Modify/Edit/EditRecruitmentPage';
import AssessmentsOverviewPage from '../Pages/Assessments/Overview/AssessmentsOverviewPage';
import InviteCandidatesPage from '../Pages/Candidates/Invite/InviteCandidatesPage';
import CandidatesOverviewPage from '../Pages/Candidates/Overview/CandidatesOverviewPage';
import CandidateResultsPage from '../Pages/Candidates/Results/CandidateResultsPage';
import CareerBuilderPage from '../Pages/CareerPages/CareerBuilderPage';
import CheckoutPage from '../Pages/CheckoutPage/CheckoutPage';
import RoutePath from './RoutePath';
import DesktopOnlyRoutes from './DesktopOnlyRoutes';

export const companyRouter: RouteObject[] = [
    {
        index: true,
        element: <AssessmentsOverviewPage />,
    },
    {
        element: <DesktopOnlyRoutes />,
        children: [
            {
                path: RoutePath.CandidatesOverview,
                element: <CandidatesOverviewPage />,
            },
            {
                path: RoutePath.CandidateResult,
                element: <CandidateResultsPage />,
            },
            {
                path: RoutePath.CreateAssessment,
                element: <CreateRecruitmentPage />,
            },
            {
                path: RoutePath.EditRecruitment,
                element: <EditRecruitmentPage />,
            },
            {
                path: RoutePath.Checkout,
                element: <CheckoutPage />,
            },
            {
                path: RoutePath.CareerBuilder,
                element: <CareerBuilderPage />,
            },
            ...[
                RoutePath.AttractApplicants,
                RoutePath.AttractApplicantsCareerPage,
                RoutePath.AttractApplicantsInvite,
                RoutePath.AttractApplicantsJobBoards,
            ].map((path) => ({ path, element: <InviteCandidatesPage /> })),
            ...[
                RoutePath.AccountSettings,
                RoutePath.AccountSettingsGeneral,
                RoutePath.AccountSettingsNotifications,
                RoutePath.AccountSettingsPayment,
                RoutePath.AccountSettingsEmailTemplates,
                RoutePath.AccountSettingsTeamMembers,
                RoutePath.AccountSettingsCompanyProfile,
                RoutePath.AccountSettingsCareerPage,
            ].map((path) => ({ path, element: <AccountSettingsPage /> })),
        ],
    },
];

const CompanyRoutesContextsProvider = () => (
    <CompanyProvider>
        <AccountSettingsProvider>
            <Outlet />
        </AccountSettingsProvider>
    </CompanyProvider>
);

export default CompanyRoutesContextsProvider;

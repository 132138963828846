import React from 'react';
import { Stack, Container } from '@talentmesh/core';
import ContentSection from './Components/ContentSection';
import HeaderSection from './Components/HeaderSection';
import FooterSection from './Components/FooterSection';

function RecruitmentOnboardingSection(): JSX.Element {
    return (
        <Stack direction="column" justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
            <Container maxWidth="lg">
                <Stack direction="column" justifyContent="center" alignItems="center">
                    <HeaderSection />
                    <ContentSection />
                    <FooterSection />
                </Stack>
            </Container>
        </Stack>
    );
}

export default RecruitmentOnboardingSection;

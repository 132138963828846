import React from 'react';
import { Link } from 'react-router-dom';
import { TMTopBar } from '@talentmesh/core';
import RoutePath from '../../Routing/RoutePath';
import useCompanyIdRoute from '../../Routing/useCompanyIdRoute';

const EmptyTopBar = () => {
    const rootPath = useCompanyIdRoute(RoutePath.CompanyRoot);

    return <TMTopBar position="static" logoClickURL={rootPath} logoLinkComponent={Link} logoColor="primary" />;
};

export default EmptyTopBar;

import { CreateAssessmentDTO } from '../../../../../DTO/Assessments/CreateAssessmentDTO';
import { AssessmentStatus, TestTypes } from '../../../../../Models/Configuration';
import ProcessingState from '../../../../../Models/ProcessingState';
import { ApplicantInformation } from '../../Models/ApplicantInformation';
import { DefaultJobAd, JobAd, mapJobAdModel2Dto } from '../../Models/JobAd';
import { JobAdGenerationError } from '../../Models/JobAdGenerationError';
import { DefaultJobDetails, JobDetails } from '../../Models/JobDetails';
import TestInfo from '../../Models/TestInfo';
import { mapJobDetailsModel2Dto } from '../../Steps/JobDetailsStep/JobDetailsStepUtils';

export interface CreateRecruitmentContextState {
    recruitmentId: string;
    jobDetails: JobDetails;
    jobAd: JobAd;
    includedTests: TestTypes[];
    testInfos: TestInfo[];
    applicantInformation: Array<ApplicantInformation>;
    recruitmentCreationOption: AssessmentStatus | '';
    regenerateAtStep2: boolean;
    showErrorDialog?: JobAdGenerationError | undefined;
    testLanguage: string;
    includesExperienceCriteria: boolean;
    experienceCriteria: Array<string>;

    processingState: ProcessingState;
    experienceCriteriaProcessingState: ProcessingState;
}

export const DefaultTestLanguageFirstOption = 'applicant';

export const DefaultCreateRecruitmentContextState: CreateRecruitmentContextState = {
    recruitmentId: '',
    jobDetails: DefaultJobDetails,
    jobAd: DefaultJobAd,
    applicantInformation: [],
    includedTests: [],
    testInfos: [],
    processingState: ProcessingState.Idle,
    recruitmentCreationOption: '',
    regenerateAtStep2: false,
    testLanguage: DefaultTestLanguageFirstOption,
    includesExperienceCriteria: true,
    experienceCriteria: [],
    experienceCriteriaProcessingState: ProcessingState.Idle,
};

export function mapCreateRecruitmentContextState2Dto(
    state: CreateRecruitmentContextState,
    assessmentStatus: AssessmentStatus,
): CreateAssessmentDTO {
    const { jobDetails, jobAd, includedTests, applicantInformation, includesExperienceCriteria } = state;
    return {
        jobDetails: mapJobDetailsModel2Dto(jobDetails),
        jobAd: mapJobAdModel2Dto(jobAd),
        includedTests,
        applicantInformation: applicantInformation.map(({ informationType, informationCategory }) => {
            return {
                informationType,
                informationCategory,
            };
        }),
        assessmentStatus,
        locale: state.testLanguage === 'applicant' ? null : state.testLanguage,
        experienceCriteria: includesExperienceCriteria
            ? state.experienceCriteria.map((criterion) => {
                  return {
                      criterion,
                  };
              })
            : [],
    };
}
